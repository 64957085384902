import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./style.css";

const { Box, Button, Stack, Typography, Popover, TextField } = require("@mui/material");

const DateWrapper = ({ dateRange,setDateRange, setAnchorEl }) => {

    const [expanded, setExpanded] = useState(false);
    const [fromDate, setFromDate] = useState(dateRange?.from);
    const [toDate, setToDate] = useState(dateRange?.to);

    const handleCancel = () => {
        setAnchorEl(null);
        setExpanded(false);
    };

    const handleApply = () => {
        if(fromDate && toDate){
            setDateRange(
                {
                    from: fromDate,
                    to: toDate
                }
            )
            setAnchorEl(null);
            setExpanded(false);
        }
    };

    return (
        <div style={{
            margin: '10px',
            padding: '19px 10px 6px 10px',
        }}>
            <Stack
                direction="column"
                justifyContent="flex-start"
                className="DateBlock"
            >
                <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        letterSpacing: "0em",
                        mr: 2,
                        mb: 1,
                    }}
                >
                    From:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                        disableFuture
                        format="DD/MM/YYYY"
                        maxDate={toDate}
                        value={fromDate}
                        onChange={(date) => setFromDate(date?.$d || "")}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="ddd MMMM DD" 
                    />
                </LocalizationProvider>
                <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        letterSpacing: "0em",
                        mr: 2,
                        mt: 2,
                        mb: 1,
                    }}
                >
                    To:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        disableFuture
                        //minDate={fromDate}
                        value={toDate}
                        onChange={(date) => setToDate(date?.$d || "")}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="ddd MMMM DD"
                    />
                </LocalizationProvider>
            </Stack>
            <div style={{ marginTop: '10px' }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleApply}>Apply</Button>
            </div>
        </div>
    );
};

export default DateWrapper