import * as CronofyElements from "cronofy-elements";
import { useEffect } from "react";
import { useState } from "react";
import { TextField, Typography, Button, InputAdornment } from "@mui/material";
import AddIcon from "src/components/logos/addIcon.png";
import ActiveIcon from "src/components/logos/activeIcon.png"
import NoEdit from "src/components/logos/noEdit.png"
import GoogleIcon from "src/components/logos/googleIcon.png";
import AppleIcon from "src/components/logos/appleIcon.png";
import Outlook from "src/components/logos/outlookIcon.png";
import MicrosoftIcon from "src/components/logos/microsoftIcon.png";
import ExchangeIcon from "src/components/logos/exchangeIcon.png";
import "./style.css";

export const CalendarSyncWrapper = ({ options }) => {
  const [element, setElement] = useState(null);

  useEffect(() => {
    try {
      if (!element) {
        setElement(CronofyElements.CalendarSync(options));
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try { 
    if (element && options.element_token) {
      element.update(options);
    }
  } catch (error) {
    console.log(error);
    
  }
  }, [options]);

  return <div id={options.target_id} />;
};

// export const CalendarSyncWrapper = ({ options, email }) => {
//   // console.log(options);
//   const [element, setElement] = useState(null);
//   const [isUpdate,setIsUpdate]=useState(false)
//   const [isCreate,setIsCreate]=useState(false)

//   // useEffect(() => {
//   //   if (!element) {
//   //     setElement(CronofyElements.CalendarSync(options));
//   //   }
//   // }, []);

//   // useEffect(() => {
//   //   if (element) {
//   //     element.update(options);
//   //   }
//   // }, [options]);

//   const handleUpdate = () => {
//     setIsUpdate(true)
//   };

//   const handleUpdateBack =()=>{
//     setIsUpdate(false)
//   }

//   const handleCreate = () => {
//     setIsCreate(true)
//   };

//   const handleCreateBack =()=>{
//     setIsCreate(false)
//   }

//   return (
//     <div id={options.target_id} >
//       <div >
//         <div style={{marginBottom:'10px'}}>
//           <Typography sx={{ fontSize: "14px", color: "#050C46",fontWeight:500 }}>{isCreate ? 'Select Source' : 'Calendar accounts'}</Typography>
//           {!isCreate ?( 
//           <TextField
//             value={email}
//             // disabled={isUpdate ? true : false}
//             variant="outlined"
//             size="small"
//             fullWidth
//             sx={{ flexGrow: 1,marginTop:"10px",color:'#050C46' }}
//             // onChange={(e) => setUserName(e.target.value)}
//             InputProps={{
//               startAdornment:isUpdate ?(
//                 <InputAdornment position="start">
//                   <img src={NoEdit} style={{marginLeft:'10px'}}/>
//                 </InputAdornment>
//               ) :null,
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <p style={{fontSize:'14px',color:'#050C46',fontWeight:'600'}}>Active</p>
//                   <img src={ActiveIcon} style={{marginLeft:'10px'}}/>
//                 </InputAdornment>
//               )
//             }}
//           />) : (
//             <div style={{display:'flex',flexDirection:'row',margin:'10px'}}>
//               <Button variant="outlined" 
//               fullWidth
//               size="small"
//              className="createButton"
//              sx={{border:'1px solid #ECE9F5',margin: '10px'}}
//               startIcon={(
//                 <img src={GoogleIcon}/>
//               )}>
//                 Google
//               </Button>
//               <Button variant="outlined" 
//               fullWidth
//               size="small"
//               className="createButton"
//               sx={{border:'1px solid #ECE9F5',margin: '10px'}}
//               startIcon={(
//                 <img src={AppleIcon}/>
//               )}>
//                 iCloud
//               </Button>
//               <Button variant="outlined" 
//               fullWidth
//               size="small"
//               className="createButton"
//               sx={{border:'1px solid #ECE9F5',margin: '10px'}}
//               startIcon={(
//                 <img src={MicrosoftIcon}/>
//               )}>
//                 Office 365
//               </Button>
//               <Button variant="outlined" 
//               fullWidth
//               sx={{border:'1px solid #ECE9F5',margin: '10px'}}
//               size="small"
//               className="createButton"
//               startIcon={(
//                 <img src={ExchangeIcon}/>
//               )}>
//                 Exchange
//               </Button>
//               <Button variant="outlined" 
//               fullWidth
//               sx={{border:'1px solid #ECE9F5',margin: '10px'}}
//               size="small"
//               className="createButton"
//               startIcon={(
//                 <img src={Outlook}/>
//               )}>
//                 Outlook
//               </Button>
//             </div>
//           )}
//         </div>
//         <div style={{ display: "flex", flexDirection: "row" ,marginTop:"20px"}}>
//           {!isCreate && 
//           <Button
//             variant="outlined"
//             fullWidth
//             color="inherit"
//             size="small"
//             onClick={isUpdate ? handleUpdateBack :handleUpdate }
//             sx={{borderRadius:'8px',
//             color:'#050C46',
//             border:'1px solid #ECE9F5',
            
//           }}
//           >
//             {!isUpdate ?  "Edit account" :" Back"}
//           </Button>}
//           {!isUpdate && 
//            <Button
//             variant={isCreate ? "outlined" :"contained"}
//             fullWidth
//             color="inherit"
//             size="small"
//             onClick={ isCreate ? handleCreateBack :handleCreate}
//             sx={{
//               marginLeft:'10px',
//             borderRadius:'8px',
//             backgroundColor:!isCreate ? '#5761FE':'#F8F7FC',
//             border:isCreate ? '1px solid #ECE9F5' : '1px solid #5761FE',
//             color:isCreate ? '#050C46' : 'white',
//             '&:hover': {
//               backgroundColor: isCreate ? '#F8F7FC' : '#5761FE',
//             }
//           }}
//           startIcon={
//             !isCreate ? <img src={AddIcon} /> : null
//           }
//           >
//            {isCreate ? 'Back' : 'Add new account'}
//           </Button>}
//         </div>
//       </div>
//     </div>
//   );
// };
