import "../style.css";
import StepOneIcon from "src/components/logos/StepOneIcon.png";
import { Typography, Stack, Button, Divider, IconButton, alpha } from "@mui/material";
import Check from "src/components/logos/Check.png";
import StepItem from "./StepItem";
import StepEmailContent from "./StepEmailContent";

export const TimelineStepThree = (props) => {
  const {
    onNext,
    onBack,
    disabled,
    emailSeqStatus,
    followUpStep,
    emailStatus,
  } = props;
  const steps = [
    {
      stepTitle: "NOW",
      stepText: "Send MEETING CONFIRMATION email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["1"]}
          status={emailStatus}
        />
      ),
    },
    {
      stepTitle: "1 HOUR BEFORE MEETING (BASED ON MEETING SCHEDULED)",
      stepText: "Send the 1 HOUR MEETING REMINDER email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["2"]}
          status={emailStatus}
        />
      ),
    },
    {
      stepTitle: "AT MEETING TIME (BASED ON THE MEETING SCHEDULED)",
      stepText: "Send the MEETING HAPPENING NOW email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["3"]}
          status={emailStatus}
        />
      ),
    },
  ];

  return (
    <Stack 
      spacing={3} 
      gap={3} 
      direction="column" 
      className="contentBorder"
      sx={{
        backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`
      }}
    >
      {/* <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography
            variant="h6"
            sx={{ color: "#050C46", fontSize: "16px", fontWeight: "700" }}>
            Now
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            Send MEETING CONFIRMATION email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            1 Hour Before Meeting (Based On Meeting Scheduled)
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            Send the 1 Hour Meeting Reminder email
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            At Meeting time (Based On The Meeting Scheduled)
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            Send the MEETING HAPPENING NOW email
          </Typography>
        </Stack>
      </> */}
      {steps.map((step, index) => (
        <>
          {" "}
          <StepItem
            key={index}
            stepTitle={step.stepTitle}
            stepText={step.stepText}
            stepContent={step.stepContent}
            completed={!!emailSeqStatus?.[`${index + 1}`]}
          />
          {index < steps.length - 1 && (
            <Divider sx={{ "&.MuiDivider-root": { my: 0 } }} />
          )}
        </>
      ))}
      <Stack direction="row" spacing={1}>
        <Button
          fullWidth
          size="small"
          className="BackButton"
          onClick={onBack}
          // disabled={disabled}
          variant="outlined"
        >
          Back
        </Button>

        <Button
          fullWidth
          className="currentButton"
          onClick={onNext}
          // disabled={disabled}
        >
          Next Stage
        </Button>
      </Stack>
    </Stack>
  );
};
