import {
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import CheckIcon from "@untitled-ui/icons-react/build/esm/Check";
import { useCallback } from "react";
import { useState } from "react";
import { TimelineStepOne } from "./TimelineSteps/TimelineStepOne";
import CloseIcon from "@mui/icons-material/Close";
import { TimelineStepTwo } from "./TimelineSteps/TimelineSteoTwo";
import { TimelineStepThree } from "./TimelineSteps/TimelineStepThree";
import { TimelineStepFour } from "./TimelineSteps/TimelineStepFour";
import { TimelineStepFive } from "./TimelineSteps/TimelineStepFive";

const StepIcon = (props) => {
  const { active, completed, icon } = props;

  const highlight = active || completed;

  return (
    <Avatar
      sx={{
        borderRadius: "50%",
        height: 40,
        width: 40,
        ...(highlight && {
          backgroundColor: theme => completed ? "#00AA38" : theme.palette.primary.main,
          color: "primary.contrastText",
        }),
      }}
      variant="rounded"
    >
      {completed ? (
        <SvgIcon>
          <CheckIcon />
        </SvgIcon>
      ) : (
        icon
      )}
    </Avatar>
  );
};

export const TimelLinePage = (props) => {
  const theme = useTheme()
  const { open, setTimelineOpen, timelineData } = props;
  const stepRef = {
    initial: 0,
    conversation: 1,
    meetingBooked: 2,
    meetingMissed: 3,
  };
  const [activeStep, setActiveStep] = useState(
    stepRef[timelineData.enrolledFor] || 0
  );
  const [expand, setExpand] = useState(null);

  const handleNext = useCallback(() => {
    setActiveStep((prevState) => prevState + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevState) => prevState - 1);
  }, []);

  const steps = useMemo(() => {
    return [
      {
        label: "Initial Email Sequence",
        content: (
          <TimelineStepOne
            onNext={handleNext}
            followUpStep={timelineData?.nextFollowUpStep}
            emailSeqStatus={timelineData?.emailSeqStatus?.initial?.prospect}
            emailStatus={timelineData?.emailStatus}
            disabled={expand === 0 && activeStep !== 0}
          />
        ),
      },
      {
        label: "Conversation Sequence",
        content: (
          <TimelineStepTwo
            onBack={handleBack}
            onNext={handleNext}
            followUpStep={timelineData?.nextFollowUpStep}
            emailSeqStatus={
              timelineData?.emailSeqStatus?.conversation?.prospect
            }
            emailStatus={timelineData?.emailStatus}
            disabled={expand === 1 && activeStep !== 1}
          />
        ),
      },
      {
        label: "Prospect Books a Meeting",
        content: (
          <TimelineStepThree
            onBack={handleBack}
            onNext={handleNext}
            followUpStep={timelineData?.nextFollowUpStep}
            emailSeqStatus={
              timelineData?.emailSeqStatus?.meetingBooked?.prospect
            }
            emailStatus={timelineData?.emailStatus}
            disabled={expand === 2 && activeStep !== 2}
          />
        ),
      },
      {
        label: "Prospect Misses a Meeting",
        content: (
          <TimelineStepFour
            onBack={handleBack}
            onNext={handleNext}
            followUpStep={timelineData?.nextFollowUpStep}
            emailSeqStatus={
              timelineData?.emailSeqStatus?.meetingMissed?.prospect
            }
            emailStatus={timelineData?.emailStatus}
            disabled={expand === 3 && activeStep !== 3}
          />
        ),
      },
      // {
      //   label: "Prospect Email Reply",
      //   content: (
      //     <TimelineStepFive
      //       onBack={handleBack}
      //       onNext={handleNext}
      //       disabled={expand === 4 && activeStep !== 4}
      //     />
      //   ),
      // },
    ];
  }, [handleBack, handleNext, activeStep, expand]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setTimelineOpen(false)}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setTimelineOpen(false);
          }}
          style={{ position: "absolute", right: 8, top: 4 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{
              "& .MuiStepConnector-line": {
                borderLeftColor: "divider",
                borderLeftWidth: 2,
                ml: 1,
              },
              "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                borderColor: "#00AA38",
              },
              "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
                borderColor: "#00AA38",
              },
              "& .MuiStepLabel-root .Mui-completed": {
                color: "#00AA38",
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: theme.palette.primary.main,
              },
              border: "1px solid #F8F7FC",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "10px",
            }}
          >
            {steps.map((step, index) => {
              const isCurrentStep = activeStep === index;
              console.log(activeStep, "activeStep");

              return (
                <Step key={step.label} expanded={expand === index}>
                  <StepLabel
                    StepIconComponent={StepIcon}
                    onClick={() => {
                      if (expand === index) {
                        setExpand(null);
                      } else {
                        setExpand(index);
                      }
                    }}
                  >
                    <Typography
                      sx={{ ml: 2, fontSize: "16px", fontWeight: "700" }}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                  <StepContent
                    sx={{
                      borderLeftColor: theme.palette.primary.main,
                      borderLeftWidth: 2,
                      ml: "20px",
                      mr: "10px",
                      ...(isCurrentStep && {
                        pt: 2,
                        pb: 4,
                        pl: 5,
                      }),
                    }}
                  >
                    {step.content}
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </DialogContent>
      </Dialog>
    </>
  );
};
