import React, { useCallback, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem 
} from "@mui/material";
import queryString from "query-string";

const campaignTabs = [
  {
    label: "Leads",
    value: "leads",
  },
];

const allTabs = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Leads",
    value: "unSentEmails",
  },
  {
    label: "Sent",
    value: "emailsSent",
  },
  {
    label: "Opened",
    value: "views",
  },
  {
    label: "Clicks",
    value: "clicks",
  },
  {
    label: "Page Views",
    value: "pageViewAt",
  },
  {
    label: "Replies",
    value: "replies",
  },
  {
    label: "Video Views",
    value: "videoViewed",
  },
  {
    label: "Website Clicks",
    value: "isWebSiteVisit",
  },
  {
    label: "Conversations",
    value: "conversations",
  },
  {
    label: "Meetings",
    value: "meetings",
  },
  {
    label: "Unsubscribe",
    value: "unsubscribe",
  },
  {
    label: "Bounced",
    value: "bounced",
  },
  {
    label: "Email Blocklist",
    value: "emailBlocklist",
  },
  {
    label: "Domain Blocklist",
    value: "domainBlocklist",
  },
];

export const CustomerListDropdown = (props) => {
  const {
    onFiltersChange,
    onAIFiltersChange,
    campaignView,
    selectedTab,
    setTab,
    setSearchQuery,
  } = props;
  
  const tabs = campaignView ? campaignTabs : allTabs;
  const [currentTab, setCurrentTab] = useState(campaignView ? "leads" : "all");
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (selectedTab !== "") {
      handleQueryTabRequest(selectedTab);
    } else {
      const parsed = queryString.parse(window.location.search);
      handleQueryTabRequest(parsed.tab);
    }
  }, [selectedTab]);

  const handleQueryTabRequest = (value) => {
    console.log(value, "queryTabRequest");
    const validTabs = ['clicks', 'conversations', 'meetings', 'replies', 'pageViewAt', 'isWebSiteVisit'];
    
    if (validTabs.includes(value)) {
      handleTabsChange(value);
    } else {
      handleTabsChange(campaignView ? "leads" : value || "all");
    }
  };

  const handleTabsChange = useCallback((value) => {
    onAIFiltersChange?.(value);
    setCurrentTab(value);
    setTab(value);
    setSearchQuery("");

    setFilters((prevState) => {
      const updatedFilters = {
        ...prevState,
        hasAcceptedMarketing: undefined,
        isProspect: undefined,
        isReturning: undefined,
        clicks: false,
        conversations: false,
        meetings: false,
        unsubscribe: false,
        bounce: false,
      };

      switch (value) {
        case "clicks":
          updatedFilters.clicks = true;
          break;
        case "conversations":
          updatedFilters.conversations = true;
          break;
        case "meetings":
          updatedFilters.meetings = true;
          break;
        case "unsubscribe":
          updatedFilters.unsubscribe = true;
          break;
        case "bounce":
          updatedFilters.bounce = true;
          break;
      }

      return updatedFilters;
    });
  }, [onAIFiltersChange, setTab, setSearchQuery]);

  const handleDropdownChange = (event) => {
    handleTabsChange(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 200, paddingLeft: 2 }}>
    <FormControl fullWidth sx={{ height: '40px' }}>
      <InputLabel>Filter</InputLabel>
      <Select
        value={currentTab}
        label="Filter"
        onChange={handleDropdownChange}
        sx={{
          height: '40px',  
        //   paddingLeft: '12px', // Add padding to the left
        }}
      >
        {(campaignView ? campaignTabs : tabs).map((tab) => (
          <MenuItem key={tab.value} value={tab.value}>
            {tab.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
  );
};

CustomerListDropdown.propTypes = {
  onFiltersChange: PropTypes.func,
  onAIFiltersChange: PropTypes.func,
  campaignView: PropTypes.bool,
  selectedTab: PropTypes.string,
  setTab: PropTypes.func,
  setSearchQuery: PropTypes.func,
};