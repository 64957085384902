import * as CronofyElements from "cronofy-elements";
import { useEffect } from "react";
import { useState } from "react";

export const AvailabilityRulesWrapper = ({ options }) => {
  //   console.log(options);
  const [element, setElement] = useState(null);

  useEffect(() => {
    if (!element && options.element_token) {
      setElement(CronofyElements.AvailabilityRules(options));
    }
  }, []);

  useEffect(() => {
    if (element && options.element_token) {
      element.update(options);
    }
  }, [options]);

  return <div id={options?.target_id} />;
};
