import { Box, FormControl, InputLabel, MenuItem, Select, Typography,
  CircularProgress, useTheme, alpha, SvgIcon } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SiHubspot, SiZoho } from "react-icons/si";
import {
  API_SERVICE_BACKEND_2,
} from "src/config";
import { chunk, debounce } from "lodash";
import { useAuth } from "src/hooks/use-auth";
import { wait } from "src/utils/wait";
import LoadingButton from "@mui/lab/LoadingButton";
import FastForward from "@untitled-ui/icons-react/build/esm/FastForward";
import { INTEGRATION_TYPE } from 'src/helpers/constants';
import SalesforceLogo from 'src/sections/dashboard/account/general-settings/integrations/salesforce-logo';
import axios from 'axios';


const PipedriveIcon = () => {
  return <svg width={24} height={24} viewBox="0 0 32 32" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_503_588)">
      <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z" />
      <path d="M24.9842 13.4564C24.9842 17.8851 22.1247 20.914 18.036 20.914C16.0923 20.914 14.4903 20.1136 13.8906 19.1134L13.9189 20.142V26.4847H9.74512V10.0846C9.74512 9.85644 9.68836 9.79843 9.4304 9.79843H8V6.31321H11.4889C13.0896 6.31321 13.4907 7.68461 13.6042 8.28525C14.2337 7.22834 15.8911 6 18.2359 6C22.2679 5.99871 24.9842 8.99802 24.9842 13.4564ZM20.724 13.4847C20.724 11.1131 19.1801 9.48523 17.2351 9.48523C15.6344 9.48523 13.8325 10.5421 13.8325 13.5144C13.8325 15.4568 14.9186 17.4855 17.1783 17.4855C18.837 17.4842 20.724 16.2843 20.724 13.4847Z" fill="currentColor" />
    </g>
  </svg>
}


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const Transfer = ({ emailStageStatus, selectedData, openSnackbar, transferLoading, setTransferLoading }) => {
  const [transferValue, setTransferValue] = useState('')
  const [reqProg, setReqProg] = useState(0);
  const [hstransferLoading, setHsTransferLoading] = useState(false);
  const [isZohoIntegration, setZohoIntegration] = useState(false);
  const [isHubspotIntegration, setHubspotIntegration] = useState(false);
  const [isPipedriveIntegration, setPipedriveIntegration] = useState(false);
  const [isSalesforceIntegration, setSalesforceIntegration] = useState(false);


  const { user } = useAuth();

  const theme = useTheme();

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
  });

  const makeHubspotApiRequest = async (contact) => {
    let access_token = user.integrations.hubspot?.access_token;
    let email = user?.email;

    // console.log(access_token, "access_token is coming", email);
    const payload = {
      email,
      contact,
      access_token: access_token,
      refresh_token: user.integrations.hubspot?.refresh_token,
      tokenObj: user.integrations.hubspot,
    };
    try {
      if (!access_token) {
        openSnackbar("Please go to settings and connect your HubSpot.", "warning");
      }
      const response = await fetch(`${API_SERVICE_BACKEND_2}/exportContacts`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const resData = await response.json();
      const { requestedContactCount, createdContactCount } = resData.res;
      const alreadyExistedContacts = requestedContactCount - createdContactCount;
      openSnackbar(
        `[Hubspot] ${requestedContactCount} Contact(s) requested to transfer, ${createdContactCount} Contact(s) have been created. ${alreadyExistedContacts} already exists.`,
        "success"
      );
      return true;
    } catch (error) {
      openSnackbar("Failed to export contacts.", "error");
      return false;
    }
  };

  const handleContactTransHubspot = async () => {
    setTransferLoading(true)
    if (selectedData?.length > 0) {
      const contacts = selectedData.map((contact) => {
        return {
          Last_Name: contact?.lastName,
          First_Name: contact?.firstName,
          email: contact?.email,
          company: contact?.organization,
          industry: contact?.industry,
          country: `${contact?.country === null ? "" : contact?.country}`,
          state: `${contact?.state === null ? "" : contact?.state}`,
          city: `${contact?.city === null ? "" : contact?.city}`,
          webiste: contact?.linkedin_url,
          Job_Title: contact?.description?.replace(/[()]/g, ""),
          campaignIds: contact?.campaignIds,
          b2b_sent: contact?.sentAt,
          b2b_opened: contact?.openedAt ? contact?.openedAt.length : 0,
          b2b_clicked: contact?.clickedAt,
          b2b_conversation: contact?.conversationAt ? contact?.conversationAt.length : 0,
          b2b_meeting_booked: contact?.meetingBookedAt ? contact?.meetingBookedAt.length : 0,
          b2b_stage: contact?.enrolledFor ? emailStageStatus(contact.enrolledFor) : "",
        };
      });

      // console.log({ contacts });

      setHsTransferLoading(true);

      const contactChunks = chunk(contacts, 10);

      for (let i = 0; i < contactChunks.length; i++) {
        const success = await makeHubspotApiRequest(contactChunks[i]);
        setReqProg(() => Math.ceil(((i + 1) / contactChunks.length) * 100));
        await wait(5000);
      }

      // setSelectedItems([]);
      // setSelectedData([]);
      setReqProg(0);
      setTransferLoading(false);
    } else {
      openSnackbar("Please select contacts", "warning");
    }

    setTransferLoading(false)
  };

  const makeZohoBatchUsersRequest = async (contacts) => {
    setTransferLoading(true);
    const access_token = user.integrations.zoho?.access_token;
    // console.log(user, "user");
    const payload = {
      email: user.email,
      contacts,
      access_token,
      tokenObj: user.integrations.zoho,
    };
    try {
      if (!access_token) {
        openSnackbar("Please go to settings and connect your Zoho.", "warning");
      }
      const response = await fetch(`${API_SERVICE_BACKEND_2}/zoho-contacts`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (response.status === 201) {
        // Success: Display a success toast
        // console.log(response);
        const jsonRes = await response.json();

        if (user.integrations.zoho?.access_token && jsonRes.data?.access_token) {
          user.integrations.zoho.access_token = jsonRes.data.access_token;
        }
        openSnackbar(`[Zoho] contact created successfully`, "success");
      } else {
        const errorData = await response.json();
        openSnackbar(`${errorData.message}`, "error");
      }
    } catch (error) {
      openSnackbar(`API request error: ${error.message}`, "error");
    } finally {
      setTransferLoading(false)
    }
  };

  const handleContactTransZoho = async () => {
    if (selectedData?.length > 0) {
      // console.log(selectedData, "selectedData");
      const contacts = selectedData.map((contact) => ({
        Last_Name: contact?.lastName,
        First_Name: contact?.firstName,
        Email: contact?.email,
        Department: contact?.organization ? contact?.organization : "",
        Mailing_Country: contact?.country ? contact?.country : "",
        Mailing_State: contact?.state ? contact?.state : "",
        Mailing_City: contact?.city ? contact?.city : "",
        Description: contact?.linkedin_url ? contact?.linkedin_url : "",
        Title: contact?.description?.replace(/[()]/g, ""),
        Lead_Source: "B2B Rocket Leads",
        campaignIds: contact?.campaignIds,
      }));
      setTransferLoading(true);
      // console.log("BATCH USERS: ----> ", contacts);

      const contactChunks = chunk(contacts, 10);

      for (let i = 0; i < contactChunks.length; i++) {
        const success = await makeZohoBatchUsersRequest(contactChunks[i]);
        setReqProg(() => Math.ceil(((i + 1) / contactChunks.length) * 100));
        await wait(10000);
      }

      // setSelectedItems([]);
      // setSelectedData([]);
      setTransferLoading(false);
      setReqProg(0);
    } else {
      openSnackbar("Please select contacts", "warning");
    }
  };

  const makePipedriveApiRequest = async (contact) => {
    let api_token = user.integrations.pipedrive?.api_token;
    let email = user?.email;

    const payload = {
      email,
      contact,
      tokenObj: user.integrations.pipedrive,
    };
    try {
      if (!api_token) {
        openSnackbar("Please go to settings and connect your Pipedrive.", "warning");
      }
      const response = await fetch(`${API_SERVICE_BACKEND_2}/transferto-pipedrive`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const resData = await response.json();
      const { requestedContactCount, createdContactCount } = resData.res;
      const alreadyExistedContacts = requestedContactCount - createdContactCount;
      openSnackbar(
        `[Hubspot] ${requestedContactCount} Contact(s) requested to transfer, ${createdContactCount} Contact(s) have been created. ${alreadyExistedContacts} already exists.`,
        "success"
      );
      return true;
    } catch (error) {
      openSnackbar("Failed to export contacts.", "error");
      return false;
    }
  };

  const handleContactTransPipedrive = async () => {
    setTransferLoading(true);
    if (selectedData?.length > 0) {
      const contacts = selectedData.map((contact) => {
        return {
          _id: contact._id,
          Last_Name: contact?.lastName,
          First_Name: contact?.firstName,
          email: contact?.email,
          company: contact?.organization,
          country: `${contact?.country === null ? "" : contact?.country}`,
          state: `${contact?.state === null ? "" : contact?.state}`,
          city: `${contact?.city === null ? "" : contact?.city}`,
          webiste: contact?.linkedin_url,
          Job_Title: contact?.description?.replace(/[()]/g, ""),
          campaignIds: contact?.campaignIds,
          b2b_sent: contact?.sentAt,
          b2b_opened: contact?.openedAt ? contact?.openedAt.length : 0,
          b2b_clicked: contact?.clickedAt,
          b2b_conversation: contact?.conversationAt ? contact?.conversationAt.length : 0,
          b2b_meeting_booked: contact?.meetingBookedAt ? contact?.meetingBookedAt.length : 0,
          b2b_stage: contact?.enrolledFor ? emailStageStatus(contact.enrolledFor) : "",
        };
      });

      const contactChunks = chunk(contacts, 10);

      for (let i = 0; i < contactChunks.length; i++) {
        const success = await makePipedriveApiRequest(contactChunks[i]);
        setReqProg(() => Math.ceil(((i + 1) / contactChunks.length) * 100));
        await wait(5000);
      }

      setReqProg(0);
    } else {
      openSnackbar("Please select contacts", "warning");
    }

    setTransferLoading(false);
  };

  const transferContactsToSalesforce = async () => {
    setTransferLoading(true);

    const selectedDataIds = [];
    selectedData?.forEach(d => {
      selectedDataIds.push(d._id);
    })

    if(!selectedDataIds.length) {
      setTransferLoading(false);
      return openSnackbar("Please select contacts", "warning");
    }

    try {
      await axios.post(`${API_SERVICE_BACKEND_2}/salesforce-integrations/upload-data`, {
        contactIds: selectedDataIds,
        userEmail: user.email,
      });

      openSnackbar(`Contacts uploaded to salesforce successfully!`)

    } catch(error) {
      openSnackbar("Error occured while uploading contacts", "error");
    } finally {
      setTransferLoading(false)
    }
  };

  const transferNow = (e) => {
    const transferHandler = Object.freeze({
      [INTEGRATION_TYPE.HUBSPOT]: handleContactTransHubspot,
      [INTEGRATION_TYPE.ZOHO]: handleContactTransZoho,
      [INTEGRATION_TYPE.PIPEDRIVE]: handleContactTransPipedrive,
      [INTEGRATION_TYPE.SALESFORCE]: transferContactsToSalesforce,
    })

    transferHandler[transferValue]?.()
  }

  useEffect(()=> {
    setZohoIntegration(!!user.integrations.zoho?.access_token)
    setHubspotIntegration(!!user.integrations.hubspot?.access_token)
    setPipedriveIntegration(!!user.integrations.pipedrive)
    setSalesforceIntegration(user.integrations?.salesforce?.integrations?.hasSalesforceIntegrated)
  }, [user])

  return (
    <>
    <FormControl sx={{ minWidth: "200px", maxWidth: "300px", width: "100%" }} size="small">
      <InputLabel 
        id="select-owner-label"
      >
        Transfer to
      </InputLabel>
      <Select
        labelId="select-owner-label"
        id="select-owner"
        value={transferValue}
        label="Transfer to"
        onChange={(e) => {
          if(!transferLoading)
          {
            setTransferValue(e.target.value)
          }
        }}
        sx={{
          '.MuiSelect-select': {
            display: "flex",
            alignItems: "center",
          },
          "& fieldset": {
            boxShadow: "none !important"
          },
          "& .MuiOutlinedInput-input": {
            lineHeight: "20px"
          }
        }}
      >
        <MenuItem disabled={!isHubspotIntegration} value={"hubspot"} style={{display: "flex"}}>
          <SiHubspot /> <span style={{marginLeft: "8px", fontSize:'14px'}}>{isHubspotIntegration ? "Transfer to HubSpot" : "HubSpot Not Connected"}</span>
        </MenuItem>

        <MenuItem disabled={!isZohoIntegration} value={"zoho"} style={{display: "flex"}}>
          <SiZoho /> <span style={{marginLeft: "8px", fontSize:'14px'}}>{isZohoIntegration ? "Transfer to Zoho" : "Zoho Not Connected"}</span>
        </MenuItem>

        <MenuItem disabled={!isPipedriveIntegration} value={"pipedrive"} style={{display: "flex"}}>
          <PipedriveIcon /> <span style={{marginLeft: "8px",fontSize:'14px'}}>{isPipedriveIntegration ? "Transfer to Pipedrive" : "Pipedrive Not Connected"}</span>
        </MenuItem>

        <MenuItem disabled={!isSalesforceIntegration} value={INTEGRATION_TYPE.SALESFORCE} style={{display: "flex"}}>
          <SalesforceLogo width='25' height='25' /> <span style={{marginLeft: "8px",fontSize:'14px'}}>{isSalesforceIntegration ? "Transfer to Salesforce" : "Salesforce Not Connected"}</span>
        </MenuItem>
      </Select>
    </FormControl>

    <LoadingButton
      loading={transferLoading}
      sx={{
        backgroundColor: alpha(theme.palette.primary.main, 1),
        color: theme.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
        minWidth: "120px",
      }}
      loadingPosition="start"
      startIcon={
        <SvgIcon>
          <FastForward />
        </SvgIcon>
      }
      size="small"
      // variant="outlined"
      onClick={() => {
        transferNow()
      }}
      disabled={transferLoading}
    >
      Transfer
    </LoadingButton>
    
    {transferLoading ? (
      <>
        <CircularProgressWithLabel value={reqProg} />
      </>
    ) : null}
    </>
  )
}

export default Transfer