import "../style.css";
import StepOneIcon from "src/components/logos/StepOneIcon.png";
import { Typography, Stack, Button, Divider, IconButton, alpha } from "@mui/material";
import Check from "src/components/logos/Check.png";
import StepItem from "./StepItem";
import StepEmailContent from "./StepEmailContent";

export const TimelineStepTwo = (props) => {
  const {
    onNext,
    onBack,
    disabled,
    followUpStep,
    emailSeqStatus,
    emailStatus,
  } = props;
  const steps = [
    {
      stepTitle: "+1 DAY AFTER AI CONVERSATION",
      stepText: "Send the CONVERSATION email to the prospect.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["1"]}
          status={emailStatus}
        />
      ),
    },
    {
      stepTitle: "+3 DAYS AFTER PREVIOUS EMAIL",
      stepText:
        "If the email was not opened, opened, or clicked resend AI regenerated email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["2"]}
          status={emailStatus}
        />
      ),
    },
    {
      stepTitle: "+7 DAYS AFTER PREVIOUS EMAIL",
      stepText:
        "If the email was not opened, opened, or clicked resend AI regenerated email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["3"]}
          status={emailStatus}
        />
      ),
    },
    {
      stepTitle: "+7 DAYS AFTER PREVIOUS EMAIL",
      stepText:
        "If the prospect still has not booked a meeting or replied to the email then remove prospect from the journey.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["4"]}
          status={emailStatus}
        />
      ),
    },
  ];

  return (
    <Stack 
      spacing={3} 
      gap={3} 
      direction="column" 
      className="contentBorder"
      sx={{
        backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`
      }}
    >
      {/* <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography
            variant="h6"
            sx={{ color: "#050C46", fontSize: "16px", fontWeight: "700" }}>
            +1 Day After Ai Conversation
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            Send the CONVERSATION email to the prospect
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +3 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the email was not opened, opened, or clicked resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +7 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the email was not opened, opened, or clicked resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +14 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the email was not opened, opened, or clicked resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +28 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the email was not opened, opened, or clicked resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +60 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the email was not opened, opened, or clicked resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +120 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the email was not opened, opened, or clicked resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +14 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the prospect still has not taken any ACTION (conversation, reply,
            meeting) other than open or click, then REMOVE the prospect from
            future emails. REMOVE/UNENROLL the prospect from future emails and
            ensure our unsubscribe link is working and tested.
          </Typography>
        </Stack>
      </> */}
      {steps.map((step, index) => (
        <>
          {" "}
          <StepItem
            key={index}
            stepTitle={step.stepTitle}
            stepText={step.stepText}
            stepContent={step.stepContent}
            completed={!!emailSeqStatus?.[`${index + 1}`]}
          />
          {index < steps.length - 1 && (
            <Divider sx={{ "&.MuiDivider-root": { my: 0 } }} />
          )}
        </>
      ))}
      <Stack direction="row" spacing={1}>
        <Button
          fullWidth
          size="small"
          className="BackButton"
          onClick={onBack}
          // disabled={disabled}
          variant="outlined"
        >
          Back
        </Button>

        <Button
          fullWidth
          className="currentButton"
          onClick={onNext}
          // disabled={disabled}
        >
          Next Stage
        </Button>
      </Stack>
    </Stack>
  );
};
