import { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { Box, Stack, Grid } from "@mui/material";
import { ImageLayout } from "src/pages/auth-demo/ImageLayout";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { getSessionStorage } from "src/utils/storage";
// import LeadinglyLogo from '../../assets/logo-lg.83418505.png';
import B2BRocketLogo from "../../assets/b2b rocket logo blue cropped.png";
import "./style.css";

export const Layout = (props) => {
  const { children } = props;
  const navigate = useNavigate(); 
  let location = useLocation();
  const currentUrl = location.pathname;

  const email = getSessionStorage("userEmail");

  useEffect(() => {
    if (email) {
      navigate("/dashboard/campaigns");
    }
  }, [email]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "background.default",
        width: "100vw",
        height: "100vh",
        overflow: currentUrl.includes("login") ? "hidden" : "auto",
        // display: "flex",
        // flex: "1 1 auto",
        // flexDirection: {
        //   xs: "column-reverse",
        //   md: "row",
        // },
      }}
    >
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          // flex: {
          //   xs: "1 1 auto",
          //   md: "0 0 auto",
          // },
          flexDirection: "column",
          justifyContent: {
            xs: "center",
          },
          alignItems: "center",
          maxWidth: "100%",
          // pt: 2,
          // pb: 0,
          // pl: 25,
          // pr: 4,
          width: {
            //md: 650,
            xs: "100%",
          },
        }}
        className="loginContainer"
      >
        <Box sx={{ width: { xs: "100%", sm: "80%", md: "50%" } }}>
          <Box sx={{ mb: 2 }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={2}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  height: 44,
                  width: 44,
                  marginBottom: "20px",
                }}
              >
                <img src={B2BRocketLogo} alt="Logo" />
              </Box>
            </Stack>
          </Box>
          {children}
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={5}
        className="imageContainer"
        sx={{
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          color: "common.white",
          display: { xs: "none", md: "flex" },
          justifyContent: "right",

          // flex: {
          //   xs: "0 0 auto",
          //   lg: "2 2 auto",
          // },
          // p: {
          //   xs: 8,
          //   md: 0,
          // },
        }}
      >
        <ImageLayout currentUrl={currentUrl} />
      </Grid>
    </Grid>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
