import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Stack,
  Typography,
  Tabs,
  Tab,
  Card,
  Divider,
  Grid,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { toast } from "react-hot-toast";
import axios from "axios";

import { API_SERVICE_BACKEND } from "src/config";
import activeIcon from "src/components/logos/activeIcon.png";
import { getSessionStorage } from "src/utils/storage";
import AlertBox from "src/layouts/alerts/AlertBox";
import "src/pages/style.css";

const PlansUi = ({ userSubscription, getUserDetail }) => {
  const navigate = useNavigate();
  const theme=useTheme();

  const [disBtn, setDisBtn] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [packageInterval, setPackageInterval] = useState("yearly");
  const [selectPlanId, setSelectPlanId] = useState("");

  const handleTabChangeFromTabs = (event, value) => {
    handleTabsChange(value);
  };

  const handleTabsChange = useCallback((value) => {
    setPackageInterval(value);
  }, []);

  const email = getSessionStorage("userEmail");

  useEffect(() => {
    if (!email) {
      navigate("/auth-main/register/v2");
    }
  }, [email]);

  const [couponError, setCouponError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const allPlans = [
    {
      id: "growth",
      name: "Growth Package",
      isPopular: false,
      monthlyPrice: {
        quarterly: 599,
        yearly: 299,
      },
      featuresList1: [
        "Unlimited Email & Phone Number Lookups",
        "6,000 Email Exports",
      ],
      featuresList2: [
        "Buyer Intent Data",
        "Unlimited Email Sending",
        "Unlimited Email Warmup",
        "10,000 Email Validation",
        "5,000 Active Contacts",
        "Lead Enrichment",
        "AI BDR Agent",
        "AI Auto-Reply",
        "10,000 AI Task Credits",
        "Unified Inbox",
        "CRM - Pipeline Management",
        "Unified Calendar",
        "Meeting Schedular",
        "Zapier Integration",
        "Live Chat Support",
      ],
    },
    {
      id: "scale",
      name: "Scale Package",
      isPopular: false,
      monthlyPrice: {
        quarterly: 999,
        yearly: 499,
      },
      featuresTitle: "Everything in GROWTH +",
      featuresList1: [
        "Unlimited Email & Phone Number Lookups",
        "Unlimited Email Exports",
        "10,000  Phone Number Exports",
      ],
      featuresList2: [
        "200,000 Active Contacts",
        "30,000 Email Validation",
        "20,000 AI Task Credits",
        "Multichannel Sequences: email, LinkedIn, phone",
        "Workspaces",
        "Team Functions (Team Invites & Roles)",
      ],
    },
    {
      id: "unlimited",
      name: "Unlimited Package",
      isPopular: true,
      monthlyPrice: {
        quarterly: 1399,
        yearly: 699,
      },
      featuresTitle: "Everything in SCALE +",
      featuresList1: [
        "Unlimited Email & Phone Number Lookups",
        "Unlimited Email Exports",
        "Unlimited Phone Number Exports",
      ],
      featuresList2: [
        "500,000 Active Contacts",
        "20,000 Email Validation",
        "100,000 AI Task Credits",
      ],
    },
    // {
    //   id: "custom",
    //   name: "Custom Package",
    //   isPopular: false,
    //   monthlyPrice: {
    //     quarterly: 1399,
    //     yearly: 699,
    //   },
    //   featuresTitle: "Everything in UNLIMITED +",
    //   featuresList1: [
    //     "Unlimited Email & Phone Number Lookups",
    //     "Unlimited Email Exports",
    //     "Unlimited Phone Number Exports",
    //   ],
    //   featuresList2: [
    //     "Custom Organization Seats",
    //     "DFY Account Setup",
    //     "Whitelabel & Partnerships",
    //   ],
    // },
  ];

  const handleCheckoutForm = async (planId) => {
    try {
      setDisBtn(true);
      let data = {
        userEmail: email,
        durationInMonths: packageInterval === "quarterly" ? 3 : 12,
        packageName: `${
          planId.charAt(0).toUpperCase() + planId.slice(1)
        } Package ${
          packageInterval.charAt(0).toUpperCase() + packageInterval.slice(1)
        }`,
        subscriptionPage: true,
      };

      const response = await axios.post(
        `${API_SERVICE_BACKEND}/createPlanSubscription`,
        data
      );

      if (response.data?.url) {
        window.location.href = response.data.url;
      }
      setDisBtn(false);
    } catch (error) {
      setDisBtn(false);
      toast.error(error?.response?.data?.error || error?.message);
    }
  };

  const isUpgradingPlan = () => {
    const currentPlanId = userSubscription?.subscriptions?.base?.planId;

    const currentPlanInterval =
      userSubscription?.subscriptions?.base?.durationInMonths;

    const currentPlan = allPlans.find((plan) => plan.id === currentPlanId);

    const currentPlanAmount =
      currentPlan.monthlyPrice[
        currentPlanInterval === 3 ? "quarterly" : "yearly"
      ] * currentPlanInterval;

    const selectedPlan = allPlans.find((plan) => plan.id === selectPlanId);

    const durationInMonths = packageInterval === "quarterly" ? 3 : 12;
    const selectedPlanAmount =
      selectedPlan.monthlyPrice[packageInterval] * durationInMonths;

    return selectedPlanAmount > currentPlanAmount;
  };

  const handleUpdatePlan = async (coupon) => {
    try {
      const durationInMonths = packageInterval === "quarterly" ? 3 : 12;

      let data = {
        userEmail: email,
        durationInMonths,
        packageName: `${
          selectPlanId.charAt(0).toUpperCase() + selectPlanId.slice(1)
        } Package ${
          packageInterval.charAt(0).toUpperCase() + packageInterval.slice(1)
        }`,
        isUpgrading: isUpgradingPlan(),
        coupon,
      };

      await axios.post(`${API_SERVICE_BACKEND}/updatePlanSubscription`, data);
      await getUserDetail(email);
      setIsLoading(false);
      toast.success("Subscription updated successfully");
      return true;
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) {
        setCouponError(error?.response?.data?.message);
        return;
      }
      toast.error(error?.response?.data?.error || error?.message);
      console.log(error);
      return false;
    }
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const userPlanInterval =
    userSubscription?.subscriptions?.base?.durationInMonths || "";
  const userSubscribedPlanInterval =
    userPlanInterval && userPlanInterval === 3
      ? "quarterly"
      : userPlanInterval === 12
      ? "yearly"
      : "";
  const userSubscribedPlanId =
    userSubscription?.subscriptions?.base?.planId || "";

  const isExclusive =
    userSubscription?.subscriptions?.base?.packageName?.includes("Exclusive");

  const isOldUser =
    !userSubscription?.subscriptions?.base?.monthlyCredits?.INTENT_DATA;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #ECE9F5",
      }}
    >
      {alertModal ? (
        <AlertBox
          userSubscription={userSubscription}
          isUpgradingPlan={isUpgradingPlan}
          alertModal={alertModal}
          setAlertModal={setAlertModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleUpdatePlan={handleUpdatePlan}
          couponError={couponError}
          setCouponError={setCouponError}
        />
      ) : (
        ""
      )}

      {disBtn ? (
        <div className="loader-wrap">
          <div className="spinLoder">
            <CircularProgress />
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack spacing={3} sx={{ padding: "10px 0px 0px 0px" }}>
          <Tabs
            className="custom-tab-pricing"
            onChange={handleTabChangeFromTabs}
            variant="fullWidth"
            sx={{
              border: "1px solid #ece9f5",
              backgroundColor: " #ece9f5",
              padding: "2px 10px",
              margin: "10px 0px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Tab
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Annually
                  <Typography
                    sx={{
                      backgroundColor: "#00AA38",
                      borderRadius: "20px",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "600",
                      marginLeft: "15px",
                      padding: "2px 10px",
                    }}
                  >
                    Save 50%
                  </Typography>
                </div>
              }
              value="yearly"
              sx={{
                border: packageInterval === "yearly" ? "1px solid white" : "",
                backgroundColor:
                  packageInterval === "yearly" ? "white" : "transparent",
                borderRadius: "8px",
                flex: 1,
              }}
            />
            <Tab
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Quarterly
                </div>
              }
              value="quarterly"
              sx={{
                border:
                  packageInterval === "quarterly" ? "1px solid white" : "",
                backgroundColor:
                  packageInterval === "quarterly" ? "white" : "transparent",
                borderRadius: "8px",
                flex: 1,
              }}
            />
          </Tabs>

          <Grid
            container
            spacing="8px"
            sx={{ paddingRight: "20px", paddingBottom: "25px",color:theme.palette.text.primary }}
            style={{ justifyContent: "center", marginTop: "0px" }}
          >
            {allPlans.map((plan, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Card className="sodhi" style={{ height: "100%" }}>
                    <Stack
                      spacing={2}
                      style={{
                        padding: "20px 20px",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <Stack
                        spacing={2}
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                        }}
                      >
                        {plan.isPopular ? (
                          <Typography
                            style={{
                              backgroundColor: "#FF5C1F",
                              borderRadius: "16px",
                              color: "white",
                              fontSize: "14px",
                              fontWeight: "500",
                              padding: "7px 4px",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: "white",
                                borderRadius: "16px",
                                color:theme.palette.text.primary,
                                fontSize: "14px",
                                fontWeight: "500",
                                padding: "5px 10px",
                                marginRight: "5px",
                              }}
                            >
                              {plan.name}
                            </span>
                            Most popular
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              backgroundColor: "#ECE9F5",
                              borderRadius: "16px",
                              color:theme.palette.text.primary,
                              fontSize: "16px",
                              fontWeight: "700",
                              width: "90%",
                              padding: "3px 10px",
                            }}
                          >
                            {plan.name}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            color:theme.palette.text.primary,
                            fontSize: "32px",
                            fontWeight: "700",
                          }}
                        >
                          {plan.name === "Custom Package"
                            ? "Custom"
                            : currencyFormatter?.format(
                                plan.monthlyPrice[packageInterval]
                              )}
                          {plan.name !== "Custom Package" && (
                            <span
                              style={{
                                color:theme.palette.text.secondary,
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >
                              /month
                            </span>
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color:theme.palette.text.secondary,
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                          style={{ marginTop: "6px" }}
                        >
                          Billed{" "}
                          {packageInterval.charAt(0).toUpperCase() +
                            packageInterval.slice(1)}
                        </Typography>

                        <Divider
                          sx={{ borderColor: "#ECE9F5", borderWidth: "1px" }}
                        />
                        <Box>
                          {plan.featuresList1.map((feature) => (
                            <Typography
                              sx={{
                                color:theme.palette.text.primary,
                                fontSize: "16px",
                                fontWeight: "700",
                                textAlign: "center",
                                paddingY: 0.5,
                              }}
                            >
                              {feature}
                            </Typography>
                          ))}
                        </Box>
                        <Divider
                          sx={{ borderColor: "#ECE9F5", borderWidth: "1px" }}
                        />
                        {plan.featuresTitle && (
                          <Typography
                            sx={{
                              color:theme.palette.text.primary,
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            {plan.featuresTitle}
                          </Typography>
                        )}
                        {plan.featuresList2.map((feature, idx) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                              }}
                              key={idx}
                            >
                              <img src={activeIcon} alt="Active Icon" />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingLeft: "10px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:theme.palette.text.primary,
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </div>
                            </div>
                          );
                        })}
                      </Stack>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ marginTop: "auto", backgroundColor: theme.palette.primary.main}}
                        disabled={
                          plan.id === "custom"
                            ? true
                            : userSubscription?.status === "cancelled" ||
                              (!isExclusive &&
                                !isOldUser &&
                                userSubscribedPlanId &&
                                plan.id === userSubscribedPlanId &&
                                userSubscribedPlanInterval === packageInterval)
                            ? true
                            : false || disBtn
                        }
                        onClick={() => {
                          if (plan.id === "custom") {
                            console.log("Custom Package");
                          } else if (!userSubscribedPlanId) {
                            handleCheckoutForm(plan.id);
                          } else {
                            setSelectPlanId(plan.id);
                            setAlertModal(true);
                          }
                        }}
                      >
                        {plan.id === "custom"
                          ? "Coming Soon"
                          : !isExclusive &&
                            !isOldUser &&
                            userSubscribedPlanId &&
                            plan.id === userSubscribedPlanId &&
                            userSubscribedPlanInterval === packageInterval
                          ? "Current Plan"
                          : userSubscribedPlanId
                          ? "Update Plan"
                          : "Get Started"}
                      </Button>
                    </Stack>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </div>
    </div>
  );
};

export default PlansUi;
