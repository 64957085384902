import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  ListItemText,
  Divider,
  DialogContent,
  DialogActions,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import { toast as hotToast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import TimePopup from "./timePopup";
import moment from "moment/moment";
import { cronofyApi } from "../../api/cronofy";
import { UserAvatar } from "src/assets/chatPage/UserAvatar";
import {
  API_SERVICE_BACKEND,
  cronofy,
  API_SERVICE_BACKEND_3,
  API_SERVICE_BACKEND_2,
  API_SERVICE_BACKEND_CRONOFY,
} from "src/config";
import arrowRight from "../../assets/arrowRight.png";
import { RefreshIcon } from "src/assets/chatPage/RefreshIcon";
import BouncingDotsLoader from "src/components/BouncingDotsLoader";
import LoadingButton from "@mui/lab/LoadingButton";
import { DateTimePickerWrapper } from "src/components/cronofy";
import { jobsApi } from "src/api/jobs";
import { LimitedSlotText, TypingEffect } from "./chatFile";
import { option } from "src/assets/data";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import "../style.css";

const regexChars = [
  "^",
  "$",
  ".",
  "|",
  "?",
  "*",
  "+",
  "(",
  ")",
  "[",
  "]",
  "{",
  "}",
];

const askForMeet = (options) => {
  const { client } = options;
  return `Alternatively, I found the right team member, <b>${client.name}</b>, Partnership Manager, who has worked with similar clients in your industry. We've limited slots available for a strategy session. Book a slot using this full calendar slots or pick whichever slot is convenient for you from the options below.`;
};

const getElementToken = async (accessToken, permissions) => {
  const token = await cronofyApi.getElementToken({
    sub: accessToken.sub,
    permissions,
    origin: cronofy.origin,
  });
  return token?.element_token?.token;
};
const getUserMeetings = async (email) => {
  try {
    const campaigns = await jobsApi.getUserCampaigns(email);
    const campaignIds = campaigns?.data?.map(({ _id }) => _id);
    let url = `${API_SERVICE_BACKEND}/dashboard-chart`;
    url += `/?campaignIds=${campaignIds.join(",")}`;
    const response = await fetch(url);
    const data = await response.json();
    const meetings = data?.data?.meetings;
    if (!meetings || !Array.isArray(meetings)) {
      return 0;
    }
    const totalCount = meetings.reduce((sum, convo) => sum + convo.count, 0);
    return totalCount;
  } catch (err) {
    console.log(err);
  }
};

const handleSlotConfirmation = async (notification, extra) => {
  const { slot, type, tzid } = notification;
  // const slot = JSON.stringify(slot);

  const {
    setCronofyElements,
    handlePromptForMeet,
    accessTokenStr,
    leadInfo,
    campaignData,
  } = extra;

  const meetingDate = moment(slot.start).format("DD MMM YYYY");
  const start = moment(slot.start).format("LT");
  const end = moment(slot.end).format("LT");

  // this api call can also handle tracking of meetings count ! **disabled for now
  const [status, error] = await cronofyApi.addEvent({});
  if (!status) {
    setCronofyElements([
      <div>
        <Typography variant="h6">{error}</Typography>
      </div>,
    ]);
    return;
  }

  // Increment meet count in campaign model !
  // const payload = {
  //   "operationStat.meetings": {
  //     $cond: {
  //       if: { $gt: [{ $ifNull: ["$operationStat.meetings", 0] }, 0] },
  //       then: { $add: ["$operationStat.meetings", 1] },
  //       else: 1,
  //     },
  //   },
  // };
  // const updateRes = await axios.patch(`${API_SERVICE_BACKEND}/updateCampaign`, {
  //   id: campaignData._id,
  //   payload,
  //   aggregate: true,
  // });

  handlePromptForMeet("hidden", true);
  setCronofyElements([
    <div>
      <Typography variant="title1">Event details:</Typography>
      <br />
      <Typography variant="title1">
        <strong>Date:</strong> {meetingDate}
      </Typography>
      <br />
      <Typography variant="title1">
        <strong>Time:</strong> {start} to {end}
      </Typography>
    </div>,
  ]);
};

const ChatBlock = ({
  cronofyToken,
  calenderId,
  sub,
  userEmail,
  data,
  busy,
  handleEgSelect,
  extra,
  scheduleMeet,
  handleOpenChat,
  isChatClick,
  isVideo,
  videoRef,
  videoData,
  lgUp,
  campaignId,
  firefun,
  isGlobal,
  loadMore,
  handleLoad,
  visistWebsite,
  mailbox,
  step,
  variant,
}) => {
  const {
    isUser,
    text: unprocessedText,
    quickResponses,
    eg,
    isMeetLink,
    id,
    showSchedule,
  } = data;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryParams = {};
  query.forEach((value, key) => (queryParams[key] = value));

  const { user: leadId, leadEmail, eid: emailId, fname, lname } = queryParams;
  const typingref = useRef(null);
  // Cronofy
  // const { campaignId } = useParams();

  const {
    accessToken,
    dateTimeOptions: OG_DateTimeOptions,
    // setDateTimeOptions,
    leadInfo,
    campaignData,
    handlePromptForMeet,
  } = extra;

  const campaignDetail =
    campaignData?.sequence?.value?.steps[step - 1]?.variants?.value[variant - 1]
      ?.template?.config;

  const senderName = mailbox?.fullName || "Adam";
  const compName = campaignDetail?.companyDesc || "";

  let searchPatterns = {
    organizationName: campaignData?.organisationName,
    companyName: compName,
    fname: leadInfo?.fname,
    lname: leadInfo?.lname,
    senderName: senderName,
  };

  Object.keys(searchPatterns).forEach((word) => {
    regexChars.forEach((char) => {
      searchPatterns[word] = searchPatterns[word]?.replace(char, `\\${char}`);
    });
  });

  // process text!
  let text = unprocessedText;

  const replacements = [
    {
      pattern: `(?<=\\W)${searchPatterns?.organizationName}(?=\\W)`,
      by: `<b>${searchPatterns?.organizationName}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.companyName}(?=\\W)`,
      by: `<b>${searchPatterns?.companyName}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.fname}(?=\\W)`,
      by: `<b>${searchPatterns?.fname}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.lname}(?=\\W)`,
      by: `<b>${searchPatterns?.lname}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.senderName}(?=\\W)`,
      by: `<b>${searchPatterns?.senderName}</b>`,
    },
  ];

  replacements.forEach(({ pattern, by }) => {
    if (!pattern) return;
    const modTemp = text.replace(new RegExp(pattern, "ig"), by);
    if (modTemp) {
      text = modTemp;
    } else {
    }
  });

  const [isDemo, setIsDemo] = useState(false);
  const [isMeet, setIsMeet] = useState(isMeetLink);
  const [openmeeting, setOpenmeeting] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const meetingTitleStr = `${campaignData?.companyName} <> ${leadInfo?.organization}`;
  const [meeting_title, setmeeting_title] = useState(
    meetingTitleStr
    // `${campaignData?.organisationName} <> ${leadInfo?.organization}, ${leadInfo?.fname} ${leadInfo?.lname} - (${campaignData?._id})`
  );
  const [meeting_date, setmeeting_date] = useState("");
  const [meeting_time, setmeeting_time] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [meeting_purpose, setmeeting_purpose] = useState(
    "I will attend this session as we are actively exploring similar solutions."
  );
  const [meeting_phone, setmeeting_phone] = useState("");
  const [meeting_guest, setmeeting_guest] = useState("");
  const [userDocumentId, setUserDocumentId] = useState("");
  const [userMeetingScheduleData, setuserMeetingScheduleData] = useState([]);
  const [MeetingSlot, setMeetingSlot] = useState(null);
  const [userMeetingSchedule, setuserMeetingSchedule] = useState({});
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slotDuration, setSlotDuration] = useState(30);
  const [timezones, setTimezones] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const [done, setDone] = useState(false);
  const closeDialog = () => {
    setDone(false);
  };

  const [examples, setExamples] = useState([]);
  useEffect(() => {
    const chatContainer = document.querySelector("#chat-container");
    if (campaignData?.link?.url === "") {
      chatContainer.scrollTop = chatContainer?.scrollHeight;
    }
  }, [examples]);

  // useEffect(() => {
  //   getNextThreeTimes(timezones, setExamplesTime, setExamples);
  // }, [timezones]);

  useEffect(() => {
    // Call cronofy API here to get list of available dates and set Date sate accordingly.
    // const date = [];
    // Calculate the list of dates starting from tomorrow and spanning the next 7 days
    // const startDate = moment().add(1, "days"); // Start from tomorrow
    // for (let i = 0; i < 3; i++) {
    //   date.push(startDate.format("YYYY-MM-DD"));
    //   startDate.add(1, "days");
    // }
    // setDates(date);

    const fetchAvailability = async () => {
      setLoading(true);
      const email = campaignData?.clientEmail; //getSessionStorage("userEmail");
      const url = isGlobal
        ? `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`
        : `${API_SERVICE_BACKEND_3}/get-campaign-slot-preset/${campaignData._id}`;

      // `${API_SERVICE_BACKEND_3}/get-campaign-slot-preset/${campaignData._id}`,
      // `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`,
      const userSlotPreset = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const duration = await userSlotPreset.json();
      const slotDuration = duration?.data?.slotsPreset;
      setSlotDuration(slotDuration);
      let buffer = duration?.data?.buffer;
      let data = {
        participants: [
          {
            members: [
              {
                sub: sub,
                calendar_ids: [calenderId],
                managed_availability: true,
              },
            ],
            required: 1,
          },
        ],
        // required_duration: { minutes: 288 },
        required_duration: { minutes: slotDuration ? slotDuration : 3600 },
        response_format: "slots",
        query_periods: [
          {
            start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            end: moment()
              .add(6, "days")
              .endOf("day")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          },
        ],
      };

      if (buffer && buffer.isSetBuffer) {
        data = {
          ...data,
          buffer: {
            before: { minutes: Number(buffer.before) },
            after: { minutes: Number(buffer.after) },
          },
        };
      }

      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/availability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            cronofyToken: cronofyToken,
          },
          body: JSON.stringify(data),
        }
      ).then((res) => res.json());

      // const availableSlots = responseDataLog?.data?.available_slots.filter(
      //   (slot) => {
      //     const slotStartMoment = moment
      //       .tz(slot.start, "UTC")
      //       .tz("America/New_York"); // converting UTC into EST timezone
      //     const slotStartTime = slotStartMoment.format("HH:mm");
      //     const slotStartHour = parseInt(slotStartTime.split(":")[0]);
      //     return slotStartHour
      //   }
      // );

      const exampleSlots = responseDataLog?.data?.available_slots
        .map((slot) => {
          return moment(slot.start).format("ddd, MMM Do h:mm A").toUpperCase();
        })
        .slice(0, 3);

      setExamples(exampleSlots);

      const groupedSlots = responseDataLog?.data?.available_slots.reduce(
        (result, slot) => {
          const startDate = moment.utc(slot.start).format("DD-MM-YYYY");

          if (!result[startDate]) {
            result[startDate] = [];
          }

          result[startDate].push(slot);

          return result;
        },
        {}
      );

      const groupedSlotsArray = Object.entries(groupedSlots)
        .map(([date, slots]) => {
          const dateObject = moment.utc(date, "DD-MM-YYYY");

          return {
            date: dateObject.format("YYYY-MM-DD"),
            slots: slots.map((slot) => ({
              start: moment.utc(slot.start).format("HH:mm:ss"),
              end: moment.utc(slot.end).format("HH:mm:ss"),
            })),
          };
        })
        .slice(0, 3);

      setDates(groupedSlotsArray);
      firefun(groupedSlotsArray);
      function ab() {
        return groupedSlotsArray;
      }
      setLoading(false);
    };
    // const isAfter6_30 = (starttime)=>{
    //   const startTime = new Date(starttime);
    //   const sixThirtyPm = new Date();
    //   sixThirtyPm.setHours(18, 30, 0, 0); // Set time to 6:30 pm

    //   return startTime > sixThirtyPm;
    // }
    if (calenderId && campaignData?.clientEmail) {
      fetchAvailability();
    }
  }, [calenderId, campaignData]);

  useEffect(() => {
    if (MeetingSlot && companyName) {
      const meetingDateTime = moment(MeetingSlot, "ddd, MMM Do hh:mm A");

      // Extract meeting_date and meeting_time
      const meeting_date = meetingDateTime.format("YYYY-MM-DD");
      const meeting_time = meetingDateTime.format("HH:mm");

      // Create a single object with meeting_date and meeting_time
      const extractedData = {
        meeting_date,
        meeting_time,
      };
      setuserMeetingSchedule(extractedData);
    }
  }, [MeetingSlot]);

  const handleClosemeeting = () => {
    setOpenmeeting(false);

    setmeeting_date("");
    setmeeting_title(meetingTitleStr);
    // setmeeting_with("");
    // setmeeting_link("");
    setmeeting_time("");
    setmeeting_purpose(
      "I will attend this session as we are actively exploring similar solutions."
    );
    setmeeting_phone("");
    setmeeting_guest("");
    setTimezones(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // setMeetingId("");
    // setMeetingEdit(false);
  };

  const getRegisteredAllUsers = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getallregistereduser/admin`
      );
      const data = await response.json();
      return data.data;
    } catch (err) {}
  };
  // const [meetingBookingLoader, setMeetingBookingLoader] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const addMeetingViaCronofy = async () => {
    const validEmail = validateEmail(newLeadEmail);
    if (leadInfo?.email === "anonymous" && !validEmail) {
      hotToast.error("Invalid email!!", { position: "top-right" });
      return;
    }

    let finalMail =
      leadInfo?.email === "anonymous" ? newLeadEmail : leadInfo?.email;
    let isDemo = false;
    if (leadInfo?.email === "anonymous") {
      isDemo = true;
      await axios.post(`${API_SERVICE_BACKEND_3}/demo-email`, {
        email: newLeadEmail,
      });
    }
    setDone(true);
    setOpenmeeting(true);
    // setMeetingBookingLoader(true);
    setBtnLoading(true);
    try {
      const data = await getRegisteredAllUsers();

      const dataOfUser = data.find(
        (doc) => doc.email === campaignData?.clientEmail
      );
      const startTime = moment.tz(
        `${meeting_date} ${meeting_time}`,
        "YYYY-MM-DD hh:mmA",
        timezones
      );
      // startTime.utc();
      // startTime.tz(userTimeZone);

      // Calculate endTime by adding 30 minutes to startTime
      const endTime = startTime.clone().add(slotDuration, "minutes");

      // Format startTime and endTime in the desired formats
      // const formattedStartTime = startTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
      // const formattedEndTime = endTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
      const formattedStartTime = startTime.toISOString(true);
      const formattedEndTime = endTime.toISOString(true);
      // console.log(formattedStartTime, "start", formattedEndTime, "end");
      // const startTime = moment(`${meeting_date} ${meeting_time}`, 'YYYY-MM-DD hh:mmA').format('YYYY-MM-DDTHH:mm:ss[Z]');
      // const endTime = startTime.add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss[Z]');
      var dataSend = {
        client_email: campaignData?.clientEmail,
        client_orgId: dataOfUser?.organization_id,
        email: campaignData?.clientEmail,
        lead_email: finalMail,
        summary: meeting_title,
        start: formattedStartTime,
        end: formattedEndTime,
        campaign_id: campaignData?._id,
        // meeting_date,
        // meeting_time,
        // meeting_with,
        description: "Discuss our offerings and how we can help you.",
        location: {
          description: "Virtual Meeting",
        },
        attendees: {
          invite: [
            {
              email: finalMail,
              display_name: leadInfo?.clientEmail,
            },
            ...meeting_guest
              .split(",")
              ?.filter((email) => Boolean(email.length))
              .map((email) => ({
                email: email,
              })),
          ],
        },
        isGlobal: isGlobal,
        isDemoFlag: isDemo,
        // meeting_link: "Test Link",
      };
    } catch (err) {
      setBtnLoading(false);
      hotToast.error("something went wrong!!", { position: "top-right" });
      setNewLeadEmail("");
      // setMeetingBookingLoader(false);
    }

    try {
      const url = `${API_SERVICE_BACKEND_CRONOFY}/addusermeetingsbycronofy`;
      const res = await axios.post(url, dataSend);
      if (res) {
        const data = res.data.data;
        hotToast.success("Meeting booked successfully!!", {
          position: "top-right",
        });
        setNewLeadEmail("");
        await addMeetings({ data, isDemo, email: finalMail });
      }
    } catch (error) {
      setBtnLoading(false);
      hotToast.error("something went wrong!! Please try again", {
        position: "top-right",
      });
      setNewLeadEmail("");
      // hotToast.error("something went wrong!!", { position: "top-right" });
      // setMeetingBookingLoader(false);
    }
    handleClosemeeting();
    setBtnLoading(false);
    // setMeetingBookingLoader(false);
  };

  const handleOpen3 = (question) => {
    if (examples.includes(question)) {
      const formattedDate = moment(question, "ddd, MMM Do h:mm A").format(
        "YYYY-MM-DD"
      );
      const formattedTime = moment(question, "ddd, MMM Do h:mm A").format(
        "h:mm A"
      );
      handleOpen2(formattedDate, formattedTime);
    } else {
      handleEgSelect(question);
    }
  };
  const getUserInfo = async (email) => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getuserdetail/${email}`
      );
      // Assuming the response structure matches the example you provided
      const userData = res.data; // Access the response data
      const companyName1 = res.data.data.companyName;
      const userId = res.data.data._id;
      setUserDocumentId(userId);
      setCompanyName(companyName1);

      // Check if the status is truthy (status exists and is not falsey)
      if (userData.status) {
        return userData.data; // Return the data property of the response
      } else {
        return null; // Return null or handle the error case as needed
      }
    } catch (error) {
      throw error; // You can choose to re-throw the error or handle it here
    }
  };
  const [meetCount, setMeetCount] = useState(0);
  const [sevenDaysMeet, setSevenDayMeet] = useState(0);
  const [thirtyDaysMeet, setThirtyDayMeet] = useState(0);
  const getMeetUnreadCount = async () => {
    try {
      const userData = await getUserInfo(campaignData?.clientEmail);
      const campaigns = await jobsApi.getUserCampaigns(
        campaignData?.clientEmail
      );
      const campaignIds = campaigns?.data?.map(({ _id }) => _id);
      let url = `${API_SERVICE_BACKEND}/dashboard-chart`;
      url += `/?campaignIds=${campaignIds.join(",")}`;
      const response = await fetch(url);
      const data = await response.json();
      var meetings = data?.data?.meetings;
      const isWithinLastDays = (date, days) => {
        const today = new Date();
        const dateBefore = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - days
        );
        return date > dateBefore;
      };

      // Calculate meetings in the last 7 and 30 days
      const calculateMeetings = (meetings, days) => {
        return meetings
          .filter((meeting) => {
            const meetingDate = new Date(meeting._id);
            return isWithinLastDays(meetingDate, days);
          })
          .reduce((total, meeting) => total + meeting.count, 0);
      };
      const totalMeetingsLast7Days = calculateMeetings(meetings, 7);
      const totalMeetingsLast30Days = calculateMeetings(meetings, 30);
      setSevenDayMeet(totalMeetingsLast7Days);
      setThirtyDayMeet(totalMeetingsLast30Days);
      var tempUnseenCount = 0;

      meetings.map((item) => {
        item.seen === false && tempUnseenCount++;
      });
      setMeetCount(tempUnseenCount);
      return { totalMeetingsLast7Days, totalMeetingsLast30Days };
    } catch (err) {
      console.log(err);
    }
  };
  const addMeetings = async (options) => {
    const {
      data: resData,
      isDemo: isDemoFlag,
      email: anonymous_mail,
    } = options;
    try {
      const data = await getRegisteredAllUsers();
      const dataOfUser = data.find(
        (doc) => doc.email === campaignData?.clientEmail
      );
      // var dataSend = {
      //   client_email: "dev@test.com",
      //   client_orgId: "1689755239760_b5bc23e5-16d1-45f6-b8ef-34a692671dc4",
      //   meeting_title:"MY TEST TITLE",
      //   meeting_date:"2023-10-08",
      //   meeting_time:"03:30PM",
      //   meeting_with:"Dev Comp 12",
      //   // meeting_link: "Test Link",
      // };
      var dataSend = {
        calendarId: calenderId,
        client_email: campaignData?.clientEmail,
        client_orgId: dataOfUser?.organization_id,
        meeting_title,
        meeting_date,
        meeting_time,
        // meeting_with,
        meeting_purpose,
        meeting_phone,
        // meeting_guest: meeting_guest + ", " + resData.calendarEmail,
        meeting_guest: `${meeting_guest},${resData.calendarEmail || ""}`,
        timezones,
        campaign_id: campaignData?._id,
        event_id: resData?.event_id,
        meeting_link: resData?.meeting_url,
        emailId,
        leadId,
        isDemoFlag,
        anonymous_mail,
      };

      // const resDataLog = await fetch('http://localhost:8080/api/v1/main/addusermeetings', {
      const resDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/addusermeetings`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (resDataLog.status === 200) {
        const runMeetingCount = async () => {
          const { totalMeetingsLast7Days, totalMeetingsLast30Days } =
            await getMeetUnreadCount();
          const userData = await getUserInfo(campaignData?.clientEmail);
          const totalMeetings = await getUserMeetings(
            campaignData?.clientEmail
          );
          const meeting_detail = {
            email: campaignData?.clientEmail,
            ai_agent_name: campaignData?.title,
            prospect_fullname: leadInfo?.firstName + " " + leadInfo?.lastName,
            prospect_email: leadInfo?.email,
            prospect_company: leadInfo?.organization,
            prospect_title: leadInfo?.description,
            prospect_linkedin_url: leadInfo?.linkedin_url,
            meeting_date: dataSend?.meeting_date,
            meeting_time: dataSend?.meeting_time,
            meeting_count: totalMeetings,
            meetings_booked_7days: totalMeetingsLast7Days,
            meetings_booked_30days: totalMeetingsLast30Days,
          };
          fetch(`${API_SERVICE_BACKEND}/intercom-event-meeting`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(meeting_detail),
          })
            .then((response) => response.json())
            .then((data) => console.log("Success:", data))
            .catch((error) => console.error("Error:", error));
        };
        runMeetingCount();
        setDone(false);
        handleClosemeeting();
      }
    } catch (error) {}
  };

  const handleOpen = (question, meetingTime) => {
    if (busy) return;
    handleEgSelect(question);
  };

  const handleOpen2 = (question, meetingTime) => {
    setMeetingSlot(question);
    setMeetingSlot(meetingTime);
    setOpenmeeting(true);

    // Extract meeting_date and meeting_time
    const meeting_date = moment(question).format("YYYY-MM-DD").toUpperCase();
    setmeeting_date(meeting_date);
    const meeting_time = meetingTime;
    setmeeting_time(meeting_time);
    // setmeeting_with(companyName);
  };

  const [dateTimeOptions, setDateTimeOptions] = useState({
    ...OG_DateTimeOptions,
  });

  const [dateTimeToken, setDateTimeToken] = useState(null);
  const [cronofyElements, setCronofyElements] = useState([]);

  useEffect(() => {
    if (!accessToken || !dateTimeOptions) return;
    if (dateTimeOptions.element_token) {
      return;
    }
    if (isMeet) {
      const permissions = ["availability"];
      (async () => {
        const dateTimeToken = await getElementToken(accessToken, permissions);
        setDateTimeToken(dateTimeToken);
      })();
    }
  }, [dateTimeOptions, accessToken, isMeet]);

  useEffect(() => {
    if (!dateTimeToken) return;
    setDateTimeOptions((prev) => {
      return {
        ...prev,
        element_token: dateTimeToken,
        callback: (res) => {
          if (res.notification.type !== "slot_selected") return;
          handleSlotConfirmation(res.notification, {
            setCronofyElements,
            handlePromptForMeet,
            accessTokenStr: accessToken.access_token,
            leadInfo,
            campaignData,
          });
        },
      };
    });
  }, [dateTimeToken]);

  useEffect(() => {
    if (!dateTimeOptions?.element_token) return;
    setCronofyElements((prev) => [
      ...prev,
      <DateTimePickerWrapper key={Date.now()} options={dateTimeOptions} />,
    ]);
  }, [dateTimeOptions]);

  const addEventToUserCalander = () => {};

  // const userEmail = getSessionStorage("userEmail");

  // useEffect(() => {
  //   const getmeetingschedule = async () => {
  //     try {
  //       // leadInfo
  //       const responseDataLog = await fetch(
  //         // `${API_SERVICE_BACKEND_2}/getmeetingschedule/${userDocumentId}`,
  //         `${API_SERVICE_BACKEND_3}/getmeetingschedule/${userDocumentId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       ).then((res) => res.json());
  //       setuserMeetingScheduleData(responseDataLog.data);
  //     } catch (err) {}
  //   };
  //   if (userDocumentId && !userMeetingScheduleData?.length) {
  //     getmeetingschedule();
  //   }
  // }, [userDocumentId]);

  // const generateTimeOptions = () => {
  //   const options = [];
  //   const startTime = 9; // Start time in hours
  //   const endTime = 17; // End time in hours

  //   for (let hour = startTime; hour < endTime; hour++) {
  //     for (let minute = 0; minute < 60; minute += 30) {
  //       const formattedHour = String(hour).padStart(2, '0');
  //       const formattedMinute = String(minute).padStart(2, '0');
  //       const timeOption = `${formattedHour}:${formattedMinute}`;
  //       options.push({ value: timeOption, label: timeOption });
  //     }
  //   }

  //   return options;
  // };

  // const timeOptions = generateTimeOptions();

  // 9:00 am to 5:00 pm ka time array banayein
  const timeOptions = [];
  let time = new Date();
  time.setHours(9, 0, 0); // 9:00 am se shuru karein

  while (time.getHours() < 17) {
    // 5:00 pm tak
    const formattedTime = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    timeOptions.push(formattedTime);
    time.setMinutes(time.getMinutes() + 30); // 30-minute intervals
  }

  const UserMeetingSchedule = ({ id, isUser, openMeeting }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const meetingTime = [
      "09:00AM",
      "11:00AM",
      "01:00PM",
      "03:00PM",
      "04:00PM",
      "09:30AM",
      "11:30AM",
      "01:30PM",
      "02:30PM",
      "04:30PM",
      "09:45AM",
      "11:45AM",
      "01:45PM",
      "02:45PM",
      "03:45PM",
      "09:15AM",
      "11:30AM",
      "01:30PM",
      "03:15PM",
      "04:15PM",
      "10:00AM",
      "11:15AM",
      "01:15PM",
      "02:45PM",
      "03:30PM",
    ];

    useEffect(() => {
      const chatContainer = lgUp
        ? document.querySelector("#chat-container")
        : document.querySelector("#chat-container");
      if (campaignData?.link?.url === "") {
        chatContainer.scrollTop = chatContainer?.scrollHeight;
      }
    }, [selectedDate]);

    // const links = document.querySelectorAll(".full_calander_slot");

    return (
      <>
        {!isVideo && (
          <Typography
            id={id ? id : ""}
            sx={{
              color: isUser ? "#FFFFFF" : "#111927",
              fontFamily: "Inter",
              fontSize: { xs: "14px", md: "25px" },
              lineHeight: lgUp ? "56px" : "30px",
              fontWeight: 400,
              marginTop: "15px",
            }}
            // dangerouslySetInnerHTML={{
            //   __html:
            // text === "Schedule a meet as per the availability."
            //   ? "Schedule a meet as per the availability."
            //   : showSchedule
            //   ? askForMeet({ client: { name: campaignData.senderName } })
            //   : limitedSlotText(
            //       Intl.DateTimeFormat().resolvedOptions().timeZone
            //     ),
            // }}
          >
            {
              text === "Schedule a meet as per the availability." ? (
                "Schedule a meet as per the availability."
              ) : showSchedule ? (
                askForMeet({ client: { name: campaignData.senderName } })
              ) : (
                <LimitedSlotText
                  timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                  handleClickOpen={openMeeting}
                />
              )
              // limitedSlotText(
              //     Intl.DateTimeFormat().resolvedOptions().timeZone
              //   )
            }
          </Typography>
        )}

        <Box
          id={id ? id : ""}
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: "1px solid #ECE9F5",
            backgroundColor: "#F8F7FC",
            borderRadius: "8px",
            p: 2,
            marginTop: "12px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter",
                fontSize: { xs: "14px", md: "25px" },
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Book a slot below for:{" "}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter",
                fontSize: { xs: "14px", md: "25px" },
                fontWeight: 700,
                lineHeight: "20px",
                marginLeft: "5px",
              }}
            >
              {moment().format("MMMM YYYY")}
            </Typography>{" "}
          </Box>
          <Grid item xs={12}>
            {" "}
            <InputLabel
              sx={{
                // ml: 3,
                // mt: 3,
                fontFamily: "Inter",
                fontSize: { xs: "14px", md: "25px" },
                fontWeight: 500,
                // lineHeight: lgUp ? "56px" : "30px",
                color: "#55597A",
                // mb: "6px",
              }}
            >
              Your current timezone
            </InputLabel>{" "}
            <Autocomplete
              options={option}
              ListboxProps={{
                sx: { fontSize: 25 },
              }}
              sx={{
                width: { xs: "80%", md: "95%" },
                "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                  fontSize: 25,
                },
              }}
              id="auto-highlight"
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  style={{ fontSize: "38px" }}
                />
              )}
              onChange={(event, value) => {
                setTimezones(
                  value || Intl.DateTimeFormat().resolvedOptions().timeZone
                );
              }}
              value={timezones}
            />
          </Grid>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DatePicker defaultValue={today} disablePast /> */}
              <div style={{ width: "100%" }}>
                <StaticDatePicker
                  // shouldDisableDate={handleDisable}
                  sx={{
                    "& .MuiPickersToolbar-root": {
                      color: "#111927",
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: "#2196f3",
                      border: "1px solid",
                      // backgroundColor: "#0d47a1",
                    },
                    "& .MuiPickersDay-root": {
                      fontSize: "25px", // Increase font size for day numbers
                    },
                    "& .MuiTypography-root": {
                      fontSize: "25px",
                    },
                  }}
                  value={selectedDates}
                  onChange={handleDateChange}
                  disablePast
                  maxDate={maxDate ? maxDate : undefined}
                  componentsProps={{
                    actionBar: {
                      actions: [],
                    },
                  }}
                  slotProps={{
                    actionBar: {
                      actions: [
                        {
                          name: "Custom Action",
                          onClick: () => {
                            console.log("Custom action clicked");
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "100%",
                  boxShadow: "0px 8px 8px -4px #10182808",
                  bgcolor: "white",
                  // p: 2,
                  // borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    height: "437px",
                    // minHeight: "100%",
                    border: "1px solid #F8F7FC",
                    bgcolor: "#F8F7FC",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    p: "10px 15px 10px 25px",
                    // borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "25px",
                      fontWeight: 500,
                      lineHeight: lgUp ? "56px" : "30px",
                      mb: 2,
                      marginRight: "5px",
                    }}
                  >
                    Book a slot below for:{" "}
                  </Typography>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: "100%",
                      width: "100%",
                      "&::-webkit-scrollbar": {
                        width: { xs: "4px", md: "10px" },
                        zIndex: 999,
                        // display: "none",
                      },

                      /* Track */
                      "&::-webkit-scrollbar-track": {
                        // boxShadow: "inset 0 0 5px grey",
                        borderRadius: "10px",
                        background: "#6e7d99",
                        // display: "none",
                      },

                      /* Handle */
                      "&::-webkit-scrollbar-thumb": {
                        background: "#1C2536",
                        borderRadius: "5px",
                        // display: "none",
                      },

                      /* Handle on hover */
                      "&::-webkit-scrollbar-thumb:hover": {
                        background: "#1C2536",
                        // display: "none",
                      },
                    }}
                  >
                    {timeSlotes ? (
                      !timeSlotes.length ? (
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "25px",
                            fontWeight: 500,
                            lineHeight: lgUp ? "56px" : "30px",
                            mb: 2,
                          }}
                        >
                          No free slots available{" "}
                        </Typography>
                      ) : (
                        timeSlotes.map((item) => {
                          return (
                            <Button
                              sx={{
                                mb: 1,
                                borderRadius: "6px",
                                background: "#fff",
                                // background:
                                //   selectedDate === question ? "#00AA38" : "#fff",
                                border: "1px solid #ECE9F5",
                                color: "#111927",
                                // color:
                                //   selectedDate === question ? "#fff" : "#050C46",
                                // p: 2,
                                boxShadow: 0,
                                "&:hover": {
                                  backgroundColor: "#ECE9F5",
                                  // backgroundColor:
                                  //   selectedDate === question
                                  //     ? "#00AA38"
                                  //     : "#ECE9F5",
                                  // color: "#fff",
                                  boxShadow: "0px 1px 2px 0px #1018280F",
                                },
                                fontFamily: "Inter",
                                fontSize: "25px",
                                fontWeight: 500,
                                lineHeight: lgUp ? "56px" : "30px",
                              }}
                              fullWidth
                              onClick={() =>
                                handleOpen2(
                                  selectedDates.format("YYYY-MM-DD"),
                                  item
                                )
                              }
                              variant="contained"
                            >
                              {item}
                            </Button>
                          );
                        })
                      )
                    ) : (
                      <CircularProgress
                        color="inherit"
                        sx={{ margin: "auto" }}
                      />
                    )}
                  </div>
                </Box>
              </Box>
            </div>
          </div>

          {/* <Grid container spacing={1} sx={{ mt: 1 }}>
            {dates?.map((question, index) => {
              const selectedTimeSlots = question.slots.map((slot) => {
                // return slot.start
                const time = moment.utc(slot.start, "HH:mm:ss").tz(timezones);
                return time.format("h:mm A");
              });
              return (
                <>
                  {selectedDate === question.date &&
                    [0, 1, 2, 3, 4]?.map((index) => (
                      <Grid item xs={12} sm={2.4} key={index}>
                        {selectedTimeSlots[index] && (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{
                              borderRadius: "6px",
                              background: "#fff",
                              color: "#050C46",
                              py: 1,
                              px: 2,
                              boxShadow: 0,
                              "&:hover": {
                                background: "#ECE9F5",
                                // color: "#fff",
                              },
                              fontFamily: "DM Sans",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "20px",

                              textAlign: "center",
                              border: "2px solid #ECE9F5",
                            }}
                            onClick={() =>
                              handleOpen2(
                                question.date,
                                selectedTimeSlots[index]
                              )
                            }
                          >
                            {selectedTimeSlots[index]}
                          </Button>
                        )}
                      </Grid>
                    ))}
                </>
              );
            })}
          </Grid> */}
          {/* <Button
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              backgroundColor: "#5761FE",
              color: "#fff",
              py: 1,
              px: 2,
              "&:hover": {
                backgroundColor: "#6269d9",
              },
              boxShadow: "0px 1px 2px 0px #1018280D",
              mt: 2,
            }}
            fullWidth
            onClick={handleClickOpen}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              <CalendarIcon />
            </Box>
            Show more slots
          </Button> */}
        </Box>
      </>
    );
  };

  useEffect(() => {
    const links = document.querySelectorAll(".full_calander_slot");
    links.forEach((link) =>
      link.addEventListener("click", () => {
        handleClickOpen();
      })
    );
  }, []);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisable = (date) => {
    if (
      new Date().getDate() === new Date(date).getDate() &&
      new Date().getHours() >= 18
    ) {
      return (
        new Date(date).getDay() === 0 ||
        new Date(date).getDay() === 6 ||
        new Date().getDate() === new Date(date).getDate()
      );
    } else {
      return new Date(date).getDay() === 0 || new Date(date).getDay() === 6;
    }
  };

  const [timeSlotes, setTimeSlotes] = useState(null);
  const [cronofyData, setCronofyData] = useState([]);
  const [minimumNotice, setMinimumNotice] = useState(null);
  const [maximumSchedulingTime, setMaximumScheduleTime] = useState(null);
  const [schedulingOption, setSchedulingOption] = useState("");
  const [rollingTime, setRollingTime] = useState("");
  const [maxDate, setMaxDate] = useState(undefined);
  const [selectedDates, setSelectedDates] = useState(dayjs()); // Initialize with today's date
  const handleDateChange = (date) => {
    setSelectedDates(date);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    // Allow users to deselect by clicking the selected option again
    setmeeting_purpose((prevValue) => (prevValue === newValue ? "" : newValue));
  };

  const date1 = moment().format("YYYY-MM-DD");
  const date2 = selectedDates.format("YYYY-MM-DD");
  const utctime = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  const cronofyApi3 = async () => {
    const email = campaignData?.clientEmail; //getSessionStorage("userEmail");
    const url = isGlobal
      ? `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`
      : `${API_SERVICE_BACKEND_3}/get-campaign-slot-preset/${campaignData._id}`;
    // `${API_SERVICE_BACKEND_3}/get-campaign-slot-preset/${campaignData._id}`,
    // `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`,
    const userSlotPreset = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const duration = await userSlotPreset.json();
    const slotDuration = duration?.data?.slotsPreset;
    setMinimumNotice(duration?.data?.minimumNotice);
    setMaximumScheduleTime(duration?.data?.maximumSchedulingTime);
    setSchedulingOption(duration?.data?.schedulingOption);
    setRollingTime(duration?.data?.rollingTime);
    try {
      let data = {
        participants: [
          {
            members: [
              {
                sub: sub,
                calendar_ids: [calenderId],
                managed_availability: true,
              },
            ],
            required: 1,
          },
        ],
        // required_duration: { minutes: 60 },
        required_duration: { minutes: slotDuration ? slotDuration : 30 },
        response_format: "slots",
        query_periods: [
          {
            start:
              date1 === date2
                ? utctime
                : moment(date2)
                    .startOf("day")
                    // .utc()
                    .format("YYYY-MM-DDT00:00:00[Z]"),
            end:
              date1 === date2
                ? moment().utc().format("YYYY-MM-DDT23:59:59[Z]")
                : moment(date2)
                    // .add(1, "days")
                    // .utc()
                    .format("YYYY-MM-DDT23:59:59[Z]"),
          },
        ],
      };

      let buffer = duration?.data?.buffer;
      if (buffer && buffer.isSetBuffer) {
        data = {
          ...data,
          buffer: {
            before: { minutes: buffer.before },
            after: { minutes: buffer.after },
          },
        };
      }

      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/availability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            cronofyToken: cronofyToken,
          },
          body: JSON.stringify(data),
        }
      ).then((res) => res.json());
      // setCronofyData(responseDataLog?.data?.available_periods)
      setCronofyData(responseDataLog?.data?.available_slots);
    } catch (err) {}
  };

  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [newLeadEmail, setNewLeadEmail] = useState("");

  useEffect(() => {
    setTimeSlotes(null);
    if (calenderId && cronofyToken && campaignData?.clientEmail) {
      // if (date1 === date2 && new Date().getHours() >= 18) {
      //   return;
      // } else {
      cronofyApi3();
      // }
    }
  }, [cronofyToken, calenderId, selectedDates, timezones, open, campaignData]);

  useEffect(() => {
    function generateTimeSlots(events, timezones) {
      const timeSlotsAM = [];
      const timeSlotsPM = [];
      let maxScheduleTime;

      // Check if the schedulingOption is set to rolling
      if (schedulingOption === "rolling") {
        let dayCount = rollingTime.split(" ")[0];
        let dayType = rollingTime.split(" ")[1];
        maxScheduleTime = new Date();
        if (dayType === "Day") {
          maxScheduleTime.setDate(
            maxScheduleTime.getDate() + parseInt(dayCount)
          );
        } else if (dayType === "Week") {
          maxScheduleTime.setDate(
            maxScheduleTime.getDate() + parseInt(dayCount) * 7
          );
        } else if (dayType === "Month") {
          maxScheduleTime.setMonth(
            maxScheduleTime.getMonth() + parseInt(dayCount)
          );
        }
        let newMaxDate = maxScheduleTime;
        newMaxDate.setDate(newMaxDate.getDate() - 1);
        setMaxDate(new Date(maxScheduleTime));
      } else if (schedulingOption === "custom") {
        // Check if the schedulingOption is set to custom
        maxScheduleTime = new Date(maximumSchedulingTime);
        let newMaxDate = maxScheduleTime;
        newMaxDate.setDate(newMaxDate.getDate() - 1);
        setMaxDate(new Date(maxScheduleTime));
      }
      if (minimumNotice) {
        // Check if the minimumNotice is set only
        let minimumNoticeDate = moment(minimumNotice).tz(timezones);

        for (let i = 0; i < events?.length; i++) {
          const startTime = moment(events[i].start).tz(timezones);
          const formattedTime = startTime.format("hh:mma");

          if (new Date(events[i].start) > new Date(minimumNoticeDate)) {
            if (startTime.hour() < 12) {
              timeSlotsAM.push(formattedTime);
            } else {
              timeSlotsPM.push(formattedTime);
            }
          }
        }
      } else {
        for (let i = 0; i < events?.length; i++) {
          const startTime = moment(events[i].start).tz(timezones);
          const formattedTime = startTime.format("hh:mma");
          // Check if the time is in AM or PM and push to the corresponding array
          if (startTime.hour() < 12) {
            timeSlotsAM.push(formattedTime);
          } else {
            timeSlotsPM.push(formattedTime);
          }
        }
      }
      // Concatenate the AM and PM time slots
      return timeSlotsAM.concat(timeSlotsPM);
    }

    const timeSlots = generateTimeSlots(cronofyData, timezones);

    setTimeSlotes(timeSlots);
  }, [cronofyData]);

  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };
    setBrowserWidth(window.innerWidth);
    // if (videoRef?.current) {
    //   videoRef.current.scrollTop = videoRef.current.scrollHeight;
    //   setScrollPosition(videoRef.current.scrollHeight);
    // }
    window.addEventListener("resize", handleResize);

    // window.removeEventListener("resize", handleResize);
    if (browserWidth <= 576) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [videoRef]);

  const videoRefs = useRef(null);
  const [duration, setDuration] = useState(0);
  const [firstPlay, setFirstPlay] = useState(true);
  const [videoDuration, setVideoDuration] = useState(null);

  useEffect(() => {
    const videoElement = videoRefs.current;
    if (videoElement) {
      const handleLoadedMetadata = () => {
        if (videoElement.duration) {
          setVideoDuration(videoElement.duration.toFixed(2));
        } else {
          console.error("Unable to get video duration");
        }
      };
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  }, [videoRefs]);

  const handleTimeUpdate = () => {
    setDuration(videoRefs.current.currentTime.toFixed(2));
  };

  const handlePlayTime = async () => {
    const currentDate = new Date().toISOString();
    if (firstPlay) {
      await axios.post(`${API_SERVICE_BACKEND_2}/videoAnalytics`, {
        campaignId: campaignId,
        leadId: leadId,
        emailId: emailId,
        firstPlay: firstPlay,
        playedAt: currentDate,
      });
      setFirstPlay(false);
    }
  };

  const handleVideoPause = async () => {
    await axios.post(`${API_SERVICE_BACKEND_2}/videoAnalytics`, {
      campaignId: campaignId,
      leadId: leadId,
      emailId: emailId,
      videoDuration: duration,
      totalVideoDuration: videoDuration,
    });
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (!campaignData?.companyWebsite) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      {/* <Button onClick={addMeetingViaCronofy} autoFocus /> */}
      <TimePopup
        // handleDisable={handleDisable}
        isGlobal={isGlobal}
        calenderId={calenderId}
        sub={sub}
        cronofyToken={cronofyToken}
        typingref={typingref}
        // timeSlotes={timeSlotes}
        selectedDates={selectedDates}
        option={option}
        handleOpen2={handleOpen2}
        setTimezones={setTimezones}
        timezones={timezones}
        handleDateChange={handleDateChange}
        open={open}
        handleClose={handleClose}
        campaignData={campaignData}
      />
      {/* <Dialog
          open={done}
          onClose={closeDialog}
          fullWidth
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ backgroundColor: "rgba(5, 12, 70, 0.2)" }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              color: "#050C46",
              textAlign: "center",
            }}
          >
            Meeting Booked
          </DialogTitle>
        </Dialog> */}
      <Dialog
        open={openmeeting}
        fullWidth
        maxWidth="md"
        onClose={() => {
          if (!btnLoading) {
            handleClosemeeting();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ backgroundColor: "rgba(5, 12, 70, 0.2)" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#111927",
            textAlign: "center",
          }}
        >
          Please Confirm Your Slot
        </DialogTitle>

        <Grid item xs={12}>
          {" "}
          <InputLabel
            sx={{
              ml: 3,
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#55597A",
              mb: "6px",
            }}
          >
            Your current timezone
          </InputLabel>{" "}
          <Typography
            sx={{
              width: { xs: "80%", md: "95%", fontSize: "25px" },
              ml: "auto",
              mr: "auto",
              textAlign: "left",
              border: "1px solid #5761FE",
              borderRadius: "6px",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {timezones}
          </Typography>
          {/* <Select
              // displayEmpty
              renderValue={(selected) => {
                if (selected === "") {
                  return "Placeholder";
                }
                return selected;
              }}
              value={timezones}
              // onChange={(e) => setTimezones(e.target.value)}
              sx={{ width: { xs: "80%", md: "95%" }, ml: 3 }}
              size="small" 
                        >
                        {/* <MenuItem disabled value="">
                    Placeholder
                  </MenuItem> 
              {/* {option?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))} */}
          {/* </Select> */}
        </Grid>

        <Divider />
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: "6px",
              width: { xs: "100%", md: "fit-content" },
              px: 2,
              py: "10px",
            }}
          >
            {" "}
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#111927",
              }}
            >
              {" "}
              {moment(meeting_date).format("ddd, MMM Do")},{" "}
              {meeting_time.toUpperCase()}
            </Typography>
          </Box>
          <Button
            sx={{
              border: "1px solid #ECE9F5",
              borderRadius: "6px",
              backgroundColor: "#fff",
              color: "#111927",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              ml: { xs: 0, md: 1 },
              mt: { xs: 1, md: 0 },
              width: { xs: "100%", md: "fit-content" },
            }}
            onClick={handleClosemeeting}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              <RefreshIcon />
            </Box>
            Change slot
          </Button>
        </DialogTitle>
        {leadInfo?.email === "anonymous" && (
          <Grid container columnSpacing={2} rowSpacing={2} sx={{ mx: 1 }}>
            <Grid item xs={12} md={6}>
              <InputLabel
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#55597A",
                  mb: "6px",
                }}
              >
                Enter Email :
              </InputLabel>
              <TextField
                fullWidth
                value={newLeadEmail === "anonymous" ? "" : newLeadEmail}
                onChange={(e) => setNewLeadEmail(e.target.value)}
                variant="outlined"
                placeholder="Emails"
                type="text"
                size="small"
              />
            </Grid>
          </Grid>
        )}
        {/* <DialogTitle id="alert-dialog-title">
            {" "}
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#050C46",
              }}
            >
              {" "}
              How will this meeting help you? (It's ok if you don't know yet)
            </Typography>
          </DialogTitle> */}
        <DialogContent>
          {/* <RadioGroup value={meeting_purpose} onChange={handleChange}>
              <FormControlLabel
                value="I will attend this session as we are actively exploring similar solutions."
                control={
                  <Radio
                    checkedIcon={<CheckBoxOnIcon />}
                    icon={<CheckBoxOffIcon />}
                  />
                }
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#55597A",
                }}
                label="I will attend this session as we are actively exploring similar solutions."
              />
              <FormControlLabel
                value="I will attend this session as I’m curious, and look forward to learning more."
                control={
                  <Radio
                    checkedIcon={<CheckBoxOnIcon />}
                    icon={<CheckBoxOffIcon />}
                  />
                }
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#55597A",
                  mt: { xs: 1, md: 0 },
                }}
                label="I will attend this session as I’m curious, and look forward to learning more."
              />
              <FormControlLabel
                value="I’m NOT interested, I was just clicking around."
                control={
                  <Radio
                    checkedIcon={<CheckBoxOnIcon />}
                    icon={<CheckBoxOffIcon />}
                  />
                }
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#55597A",
                  mt: { xs: 1, md: 0 },
                }}
                label="I’m NOT interested, I was just clicking around."
              />
            </RadioGroup> */}
          <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
            {/* <Grid item xs={12}>
                <InputLabel
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#55597A",
                    mb: "6px",
                  }}
                >
                  Meeting Title
                </InputLabel>
                <TextField
                  value={meeting_title}
                  fullWidth
                  onChange={(e) => setmeeting_title(e.target.value)}
                  variant="outlined"
                  placeholder="Please enter the title for the meeting."
                  type="text"
                  size="small"
                />
              </Grid> */}

            <Grid item xs={12} md={6}>
              {" "}
              <InputLabel
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#55597A",
                  mb: "6px",
                }}
              >
                What's your phone number?
                <b>(optional)</b>
              </InputLabel>
              <TextField
                fullWidth
                value={meeting_phone}
                onChange={(e) => setmeeting_phone(e.target.value)}
                variant="outlined"
                placeholder="For sending meeting details & reminders"
                type="number"
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {" "}
              <InputLabel
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#55597A",
                  mb: "6px",
                }}
              >
                Do you have any guests for the meeting?
                <b>(optional)</b>
              </InputLabel>
              <TextField
                fullWidth
                value={meeting_guest}
                onChange={(e) => setmeeting_guest(e.target.value)}
                variant="outlined"
                placeholder="Emails"
                type="text"
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* <Button onClick={handleClosemeeting}>Close</Button> */}

          {/* {leadInfo?.email === "anonymous" || !leadInfo?.email ? (
              <LoadingButton
                disabled
                color="inherit"
                variant="contained"
                size="small"
                fullWidth
                // startIcon={<img src={Download} />}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#5761FE",
                  color: "#fff",
  
                  py: 1,
                  px: 2,
                  "&:hover": {
                    backgroundColor: "#6269d9",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#6269d9",
                    color: "white", // Change the color of the loading bar here
                  },
                  "&.MuiLoadingButton-loading": {
                    "& .MuiCircularProgress-svg": {
                      color: "white", // Change the color of the loading bar here
                    },
                  },
                  textAlign: "left",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
              >
                Sender email is not defined.
              </LoadingButton>
            ) : ( */}
          <LoadingButton
            disabled={btnLoading}
            color="inherit"
            variant="contained"
            size="small"
            fullWidth
            loading={btnLoading}
            // startIcon={<img src={Download} />}
            onClick={addMeetingViaCronofy}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#5761FE",
              color: "#fff",
              py: 1,
              px: 2,
              "&:hover": {
                backgroundColor: "#6269d9",
              },
              "&.Mui-disabled": {
                backgroundColor: "#6269d9",
              },
              "&.MuiLoadingButton-loading": {
                "& .MuiCircularProgress-svg": {
                  color: "white", // Change the color of the loading bar here
                },
              },
              textAlign: "left",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
          >
            Confirm
          </LoadingButton>
          {/* )} */}
          {/* <Button
              disabled={meetingBookingLoader}
              onClick={addMeetingViaCronofy}
              fullWidth
              sx={{
                borderRadius: "8px",
                backgroundColor: "#5761FE",
                color: "#fff",
                py: 1,
                px: 2,
                "&:hover": {
                  backgroundColor: "#6269d9",
                },
                textAlign: "left",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
            >
              Confirm
            </Button> */}
        </DialogActions>
      </Dialog>
      <Grid
        item
        xs={8}
        md={10}
        lg={12}
        sx={{
          display: "flex",
          padding: "0px 0px 0px 0px",
        }}
      >
        {!data.isVideoPresent && (
          <Grid sx={{ display: "flex", padding: "12px 0px" }}>
            {isUser ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UserAvatar />
                </Box>
              </>
            ) : (
              <>
                {/* <img src={avatar} /> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <BotAvatar /> */}
                </Box>
              </>
            )}
            <ListItemText
              sx={{
                marginLeft: "5px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: { xs: "14px", md: "25px" },
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#111927",
                }}
              >
                {isUser ? "You" : `${campaignData?.companyName || ""}`}
              </Typography>
            </ListItemText>
          </Grid>
        )}

        {/* <ListItemText sx={{ textAlign: "right" }}>
            Thursday 16:56PM
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                color: "#55597A",
              }}
            >
             {moment().format("dddd hh:mm A")}
            </Typography>
          </ListItemText> */}
      </Grid>

      <Box
        sx={{
          p: lgUp ? "5px 16px 6px 16px" : "10px 16px 11px 16px",
          zIndex: 999,
          borderRadius: isUser ? "16px 16px 0px 16px" : "16px 16px 16px 16px",
          backgroundColor: isUser ? "#111927" : "#FFFFFF",
          my: isVideo ? 0 : 0,
          boxShadow: "0px 1px 2px 0px #1018280D",
          border: "1px solid #ECE9F5",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          flexWrap={isUser ? "wrap" : "nowrap"}
        >
          {isUser ? <></> : ""}
          {/* <SmartToyIcon /> */}
          <Stack spacing={1} justifyContent="center" sx={{ width: "100%" }}>
            {data.isVideoPresent ? (
              <>
                <div
                  // ref={videoRef}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: lgUp ? "" : "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: lgUp ? "25px" : "16px",
                      color: "#111927",
                      fontWeight: "700",
                      margin: "3px 0px",
                      textAlign: "center",
                    }}
                  >
                    Hi {leadInfo?.fname}, I created this special video for{" "}
                    <span
                      style={{
                        color: "#5862FF",
                        fontSize: lgUp ? "25px" : "16px",
                        fontWeight: "700",
                      }}
                    >
                      {leadInfo?.organization || "Prospect Company"}
                    </span>
                  </Typography>
                  {/* {videoData?.data.data.length !== 0 && (
                    <Typography
                      sx={{
                        fontSize: lgUp ? "42px" : "12px",
                        color: "#050C46",
                        fontWeight: "400",
                        margin: "3px 0px",
                        textAlign:'center'
                      }}
                    >
                      I’ve made an exclusive video about{" "}
                      {leadInfo?.organization || "Prospect Company"} regarding
                      our potential together.
                    </Typography>
                  )} */}
                  {/* {videoData?.data.status */}
                  {!!videoData?.video_url &&
                    videoData?.chatLinkType === "VIDEO" && (
                      <video
                        ref={videoRefs}
                        className="videoFrame"
                        controls
                        width={lgUp ? "800" : "362"}
                        height={lgUp ? "452" : "205"}
                        style={{ borderRadius: "10px", marginTop: "2px" }}
                        onTimeUpdate={handleTimeUpdate}
                        onPlay={handlePlayTime}
                        onPause={handleVideoPause}
                      >
                        <source src={videoData?.video_url} type="video/mp4" />
                      </video>
                    )}
                  <Typography
                    sx={{
                      fontSize: lgUp ? "25px" : "16px",
                      color: "#111927",
                      fontWeight: "700",
                      margin: lgUp ? "10px 0px" : "2px 0px 0px 0px",
                      lineHeight: lgUp ? "56px" : "30px",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#5862FF",
                        fontSize: lgUp ? "25px" : "16px",
                        fontWeight: "700",
                      }}
                    >
                      {leadInfo?.fname},
                    </span>{" "}
                    book a meeting with us or chat now and ask us anything.
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: lgUp ? "row" : "column",
                        margin: lgUp ? "5px 0px" : "0px 0px",
                      }}
                    >
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          border: "1px solid #5761FE",
                          backgroundColor: "#5761FE",
                          color: "white",
                          margin: lgUp ? "0px 12px" : "6px 0px 10px 0px",
                          fontSize: lgUp ? "25px" : "16px",
                          // height: lgUp ? '46px' : '100%',
                          padding: "8px 0px",
                          minWidth: lgUp ? "48%" : "100%",
                        }}
                        // size="small"
                        // fullWidth
                        logout
                        variant="contained"
                        onClick={(e) => {
                          scheduleMeet();
                        }}
                        endIcon={<img src={arrowRight} />}
                      >
                        Book A Meeting
                      </Button>
                      <Tooltip
                        title={
                          campaignData?.companyWebsite
                            ? ""
                            : "Company website is not present"
                        }
                      >
                        <Button
                          // fullWidth
                          variant="contained"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            backgroundColor: "#5761FE",
                            color: "white",
                            fontSize: lgUp ? "25px" : "16px",
                            margin: lgUp ? "0px 12px" : "6px 0px 10px 0px",
                            marginBottom: lgUp ? "0px" : "10px",
                            width: lgUp ? "30rem" : "100%",
                            backgroundColor: campaignData?.companyWebsite
                              ? "#5761FE"
                              : "grey",
                            // padding: '8px 10px'
                          }}
                          onClick={() => {
                            if (campaignData && campaignData?.companyWebsite) {
                              const url =
                                campaignData.companyWebsite.startsWith(
                                  "http://"
                                ) ||
                                campaignData.companyWebsite.startsWith(
                                  "https://"
                                )
                                  ? campaignData.companyWebsite
                                  : `http://${campaignData.companyWebsite}`;
                              window.open(url, "_blank", "noopener noreferrer");
                            }
                          }}
                          endIcon={<img src={arrowRight} />}
                        >
                          Visit Our Website
                        </Button>
                      </Tooltip>
                      {/* <Button
                        variant="outlined"
                        // size="small"
                        // fullWidth
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #5761FE",
                          fontSize: lgUp ? "25px" : "16px",
                          //maxWidth: lgUp ? "40%" : "55%",
                          alignSelf: "center",
                          padding: '8px 0px',
                          minWidth: lgUp ? '30%' : '100%'
                        }}
                        onClick={handleOpenChat}
                      // endIcon={<img src={chatButton} />}
                      >
                        Load More Info
                      </Button> */}
                    </div>

                    {/* {!isChatClick && lgUp && (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "50%",
                          border: "1px solid #5761FE",
                          backgroundColor: "#5761FE",
                          position: "absolute",
                          padding: "20px 15px",
                          marginBottom: "2px",
                          right: "20px",
                          bottom: "2%",
                        }}
                        onClick={handleOpenChat}
                      >
                        <img src={chatIcon} />
                      </Button>
                    )} */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div videoRef={videoRef}>
                  {text !== "Schedule a meet as per the availability." ? (
                    <div>
                      {" "}
                      {
                        <Typography
                          id={id ? id : ""}
                          sx={{
                            whiteSpace: "normal",
                            color: isUser ? "#FFFFFF" : "#111927",
                            fontFamily: "Inter",
                            fontSize: lgUp ? "25px" : "16px",
                            lineHeight: lgUp ? "42px" : "30px",
                            fontWeight: 400,
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          {id ? (
                            <>
                              <BouncingDotsLoader />
                            </>
                          ) : (
                            // <ReactMarkdown>{text}</ReactMarkdown>
                            <div>
                              {isMobile ? (
                                <TypingEffect text={text} videoRef={videoRef} />
                              ) : (
                                <TypingEffect text={text} videoRef={videoRef} />
                              )}
                              {!isUser && !eg && !data.isVideoPresent && (
                                <UserMeetingSchedule
                                  openMeeting={handleClickOpen}
                                  id={id ? id : ""}
                                  isUser={isUser}
                                />
                              )}
                            </div>
                          )}
                        </Typography>
                      }
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <UserMeetingSchedule
                        openMeeting={handleClickOpen}
                        id={id ? id : ""}
                        isUser={isUser}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {eg && (
              <>
                <Grid container rowSpacing={1} columnGap={1}>
                  {loading ? (
                    <Box
                      sx={{
                        margin: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {/* <label><strong>"PLEASE CONNECT A CALENDAR TO SHOW SLOTS."</strong></label>
                        <CircularProgress
                          color="inherit"
                          sx={{ margin: "auto" }}
                        /> */}
                    </Box>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: lgUp ? "row" : "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          border: "1px solid #5761FE",
                          backgroundColor: "#5761FE",
                          color: "white",
                          margin: lgUp ? "0px 12px" : "6px 0px 10px 0px",
                          fontSize: lgUp ? "25px" : "16px",
                          // height: lgUp ? '46px' : '100%',
                        }}
                        // size="small"
                        fullWidth
                        logout
                        variant="contained"
                        onClick={(e) => {
                          scheduleMeet();
                        }}
                        endIcon={<img src={arrowRight} />}
                      >
                        Book A Meeting
                      </Button>

                      <a
                        href={
                          campaignData && campaignData?.companyWebsite
                            ? campaignData.companyWebsite.startsWith(
                                "http://"
                              ) ||
                              campaignData.companyWebsite.startsWith("https://")
                              ? campaignData.companyWebsite
                              : `http://${campaignData.companyWebsite}`
                            : null
                        }
                        style={{
                          "text-decoration": "none",
                          marginRight: lgUp ? "10px" : "0px",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        // onClick={visistWebsite}
                      >
                        <Tooltip
                          title={
                            campaignData?.companyWebsite
                              ? ""
                              : "Company website is not present"
                          }
                        >
                          <Button
                            endIcon={<img src={arrowRight} />}
                            // size="small"
                            // fullWidth
                            variant="contained"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "8px",
                              backgroundColor: campaignData?.companyWebsite
                                ? "#5761FE"
                                : "grey",
                              color: "white",
                              fontSize: lgUp ? "25px" : "16px",
                              width: lgUp ? "18rem" : "100%",
                              marginBottom: lgUp ? "0px" : "10px",
                            }}
                          >
                            Visit Our Website
                          </Button>
                        </Tooltip>
                      </a>
                    </div>
                  )}
                </Grid>
              </>
            )}

            {isMeet && (
              <div>
                {/* <Button
                  variant="contained"
                  size="small"
                  onClick={addEventToUserCalander}
                >
                  Add to Calander
                </Button> */}
                {cronofyElements.map((elements) => elements)}
              </div>
            )}
          </Stack>
        </Stack>
      </Box>

      {/* {((!eg && showSchedule) ||
          (busy === false && !isUser && !eg && !examples.includes(text))) && (
            <Box
              sx={{
                p: 2,
                zIndex: 999,
                borderRadius: isUser ? "16px 16px 0px 16px" : "16px 16px 16px 0px",
                backgroundColor: isUser ? "#050C46" : "#FFFFFF",
                my: isUser ? 0 : 1,
                boxShadow: "0px 1px 2px 0px #1018280D",
                border: "1px solid #ECE9F5",
              }}
            >
              <Stack spacing={1} justifyContent="center">
                
                <UserMeetingSchedule id={id ? id : ""} isUser={isUser} />
  
              </Stack>
            </Box>
          )} */}
      {Boolean(quickResponses?.length) && (
        <>
          <Grid container rowSpacing={1} columnGap={1}>
            {quickResponses.map((question, index) => {
              return (
                <>
                  <Grid item>
                    {" "}
                    <Button
                      key={index}
                      // size="small"
                      variant="outlined"
                      sx={{
                        borderRadius: "8px",
                        // backgroundColor: "#5761FE",
                        // color: "#fff",
                        color: "#5761FE",
                        py: 1,
                        px: 2,
                        "&:hover": {
                          backgroundColor: "#6269d9",
                          color: "#fff",
                        },
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        boxShadow: "0px 1px 2px 0px #1018280D",
                      }}
                      onClick={(e) => {
                        if (busy) return;
                        handleEgSelect(question);
                      }}
                    >
                      {question}
                    </Button>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export default ChatBlock;
