import { common } from '@mui/material/colors';
import { alpha, lighten, darken } from '@mui/material/styles';
import { error, info, neutral, success, warning, withAlphas } from '../colors';
import { getPrimary } from '../utils';
export const createPalette = (config) => {
  const { colorPreset, contrast } = config;
  // test theme
  const customPalette = withAlphas({
    lightest: lighten("#EB34CC", 0.9),
    light: lighten("#EB34CC", 0.7),
    main: "#EB34CC",
    dark: darken("#EB34CC",0.1),
    darkest: darken("#EB34CC",0.2),
    contrastText: "#FFFFFF",
  })
  // test theme ends
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12)
    },
    background: {
      default: contrast === 'high' ? neutral[50] : common.white,
      paper: common.white
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    primary:  getPrimary(colorPreset),
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38)
    },
    warning
  };
};