import React, { useEffect, useState, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReactJson from "react-json-view";
import Cookies from "js-cookie";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Divider,
  Stack,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  MenuItem,
  alpha,
  useMediaQuery,
  CircularProgress,
  LinearProgress,
  Tooltip,
  Switch,
  styled,
  Select,
  DialogActions,
  ListItem,
  ListItemText,
  List,
  Snackbar,
  Pagination,
  DialogContentText,
  Chip,
  Drawer,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  SearchOutlined,
  AddOutlined,
  Close,
  DeleteOutline,
  ExpandMore,
  ExpandLess,
  InfoOutlined,
  LanguageOutlined,
  CheckCircle,
  Cancel,
  CloseOutlined,
  ReportProblemOutlined,
  BarChartOutlined,
  QueryStatsOutlined,
} from "@mui/icons-material";
import { GmailIcon } from "src/components/logos/gmailIcon";
import { MicrosoftIcon } from "src/components/logos/microsoftIcon";
import Instructions from "./instructions";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  googleWebAppConfig,
  microsoftWebAppConfig,
  API_SERVICE_BACKEND,
  API_ADMIN_SERVICE_BACKEND,
} from "../../../../config";
import "../style.css";
import { getSessionStorage, setSessionStorage } from "src/utils/storage";
import ConnectNewAccount from "src/components/connectNewAccount";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  devicePixelRatio: 2,
  clip: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        borderRadius: 8,
        color: "#111927",
        font: {
          size: 12,
        },
      },
    },
    title: {
      display: true,
    },
    /* tooltip: {
      mode: "nearest",
      axis: "x",
      intersect: false,
      enabled: (item) => {
        return item?.tooltip?.title?.[0] === "undefined"
          ? false
          : true;
      },
      // filter: (tooltipItem) => {
      //   return !bufferIndices.includes(
      //     tooltipItem.dataIndex
      //   );
      // },
      callbacks: {
        title: (tooltipItem) => {
          const endDateArray =
            tooltipItem?.[0]?.label?.split("/");
          const endDate = `${endDateArray?.[1]}/${endDateArray?.[0]}/${endDateArray?.[2]}`;
          let days = 7; // Days you want to subtract
          let startDate = new Date(endDate);
          let lastWeekDate = new Date(
            startDate?.getTime() -
              days * 24 * 60 * 60 * 1000
          );
          const formattedLastWeekDate =
            moment(lastWeekDate).format("MMM DD"); 

          const formattedEndDate =
            moment(endDate).format("MMM DD");

       
        },
        label: (tooltipItem) => {
          const ref = {
            1: "low",
            2: "medium",
            3: "high",
          };

          return `score : ${
            ref[parseInt(tooltipItem.formattedValue)]
          }`;
        },
      },
    }, */
  },
  scales: {
    x: {
      //stacked: true,
    },
    y: {
      title: {
        display: true,
        text: "Emails Sent",
        color: "#111927",
        font: {
          weight: "700",
          size: 12,
        },
      },
      border: {
        display: false,
      },

    }
  }
};

const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};
const mailBoxStatusList = [
  {
    value: "CONNECTED",
    label: "Connected",
    color: "#12B981"
  }, {
    value: "WARMUP",
    label: "Warm Up",
    color: "#FF5C1F"

  },
  {
    label: "Disconnected",
    color: "#E20E0E",
    value: "DISCONNECTED"
  },
]
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00AA38",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "#ffffff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const CustomButton = ({ label, error, handleClick, acc, setOpenSnackbar }) => {
  const [open, setOpen] = useState(false);
  const [domain, setDomain] = useState("godaddy");
  const [service, setService] = useState("google");
  const [subDomain, setSubDomain] = useState("");
  const [subDomainError, setSubDomainError] = useState("");
  const [checking, setChecking] = useState(false);
  const theme=useTheme();
  
  
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isValidDomain = (subdomain) => {
    const regex = /[^a-zA-Z0-9\-]/;
    return subdomain !== "" ? !regex.test(subdomain) : false;
  };
  const handleOpen = () => [setOpen(true)];
  const handleClose = () => {
    setOpen(false);
  };
  const [expandedSpf, setExpandedSpf] = useState(true);
  const handleExpandSPF = () => {
    setExpandedSpf(!expandedSpf);
  };

  const checkStatus = () => {
    if (isValidDomain(subDomain)) {
      setSubDomainError("");
      setChecking(true);
      setTimeout(() => setChecking(false), 5000);
    } else {
      setSubDomainError("Invalid sub domain!");
    }
  };
  const CustomDomainInstructions = () => {
    return (
      <List
        sx={{
          listStyle: "decimal",
          pl: 4,
          "& .MuiListItem-root": { p: 0, fontSize: "14px" },
          "& .MuiListItemText-root .MuiTypography-root": {
            fontWeight: "400",
            fontSize: "14px",
            color: "#ffffff",
          },
        }}
      >
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Go to your domain's DNS Settings</ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Create a new CNAME record</ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Enter the following details</ListItemText>
        </ListItem>
      </List>
    );
  };
  return (
    <>
      <Button
        onClick={
          ["SPF", "DMARC", "DKIM", "Custom Tracking", "MX"].includes(label)
            ? handleOpen
            : handleClick
        }
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          borderRadius: 1,
          py: 1,
          px: 2,
          border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
          "&:hover": {
            border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
            backgroundColor: alpha(error ? "#E20E0E" : "#00AA38", 0.1),
          },
        }}
      >
        <Typography
          sx={{ fontWeight: "700", color: theme.palette.text.primary, fontSize: "12px" }}
        >
          {label}
        </Typography>
        {error ? (
          <Cancel sx={{ color: "#E20E0E", fontSize: "22px" }} />
        ) : (
          <CheckCircle sx={{ color: "#00AA38", fontSize: "22px" }} />
        )}
      </Button>
      <Dialog open={open} onClose={handleClose} fullScreen={isSmDown}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {label === "Custom Tracking"
              ? "Set up a custom tracking subdomain"
              : "Configure Domain Authentication"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined sx={{color:theme.palette.text.primary}}/>
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: { xs: "hidden", sm: "auto" },
            // flex: {
            //   xs: `${label === "Custom Tracking" ? 0 : 1} 1 auto`,
            //   sm: "1 1 auto",
            // },
          }}
        >
          {label === "Custom Tracking" ? (
            <Stack rowGap={2}>
              <Typography
                className=""
                sx={{ fontSize: "14px", fontWeight: "400", color: theme.palette.text.primary }}
              >
                Default tracking domains can put your sending reputation at
                risk, so it's best to set up a custom tracking subdomain to
                maintain deliverability.
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400", color: theme.palette.text.primary }}
              >
                Configuring a custom subdomain will require access to your
                company's DNS.
              </Typography>
              <Stack rowGap={0.7}>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "600", color: theme.palette.text.primary }}
                >
                  Add custom subdomain
                </Typography>
                <Box
                  sx={{
                    
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    sx={{ width: { xs: "100%", sm: "100px" }, flexGrow: 1 }}
                    value={subDomain}
                    onChange={(e) => setSubDomain(e.target.value)}
                    error={!!subDomainError}
                    placeholder={"track"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: theme.palette.text.primary,
                            }}
                          >
                            .{acc?.senderEmail.split("@")?.[1]}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    helperText={subDomainError}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      loading={checking}
                      // loadingPosition="start"
                      loadingIndicator="Checking…"
                      sx={{ borderRadius: 1, py: "6px" }}
                      onClick={checkStatus}
                    >
                      <span>Check Status</span>
                    </LoadingButton>
                    {checking && (
                      <Button
                        variant="outlined"
                        sx={{ borderRadius: 1, py: "6px" }}
                        onClick={() => {
                          setChecking(false);
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Box>
                </Box>
              </Stack>
              <Stack rowGap={1}>
                <Typography
                  sx={{
                    color:theme.palette.text.primary,
                    fontSize: "14px",
                    fontWeight: "400",
                    color:theme.palette.text.primary,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Add a new CNAME record for your tracking domain or subdomain.{" "}
                  <Tooltip title={<CustomDomainInstructions />}>
                    <InfoOutlined sx={{color:theme.palette.text.secondary, fontSize: "18px" }} />
                  </Tooltip>
                </Typography>

                <Stack
                  rowGap={1}
                  sx={{
                    backgroundColor: alpha("#000000", 0.05),
                    borderRadius: 1,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color:theme.palette.text.primary,
                        width: "100px",
                      }}
                    >
                      Record Type:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: theme.palette.text.primary,
                      }}
                    >
                      CNAME
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color:theme.palette.text.primary,
                        width: "100px",
                      }}
                    >
                      Host:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color:theme.palette.text.primary,
                      }}
                    >
                      {subDomain || "track"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color:theme.palette.text.primary,
                        width: "100px",
                      }}
                    >
                      Value:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color:theme.palette.text.primary,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      prox.itrackly.com{" "}
                      <span
                        style={{
                          borderRadius: "8px",
                          padding: "4px 8px",
                          marginLeft: "8px",
                          backgroundColor: theme.palette.text.primary,
                          color: "#ffffff",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpenSnackbar(true);
                          navigator.clipboard.writeText("prox.itrackly.com");
                        }}
                      >
                        Copy
                      </span>
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: theme.palette.text.primary,
                  mt: 1,
                }}
              >
                {label === "DKIM" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon  />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={acc?.rapidApiData?.records[label]}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                )}
                {label === "MX" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={acc?.rapidApiData?.records[label]}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                )}
                {label === "DMARC" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={{
                                  DMARC: acc?.rapidApiData?.records[label],
                                }}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {/* <div>
                      <div className="pop-authenticate-domain"
                        onClick={handleExpandSPF}>
                        <p>Advanced Option</p>
                        <IconButton
                          sx={{
                            borderRadius: 1,
                            backgroundColor: "#ECE9F5",
                            color: "#5761FE",
                            p: 0.5,
                            "&:hover": { backgroundColor: "#ECE9F5" },
                          }}
                        >
                          {expandedSpf ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {expandedSpf && (<pre style={{ whiteSpace: 'pre-wrap', padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px', }}>
                          <p className="black-authencate">{'"' + label + '" :'}</p> <p className="red-authencate">{JSON.stringify(acc?.rapidApiData?.records[label], null, 2)}</p>
                        </pre>)}
                      </div>


                    </div> */}
                  </>
                )}
                {label === "SPF" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={{ SPF: acc?.rapidApiData?.records[label] }}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {/* <div>

                      <div className="pop-authenticate-domain"
                        onClick={handleExpandSPF}>
                        <p>Advanced Option</p>
                        <IconButton
                          sx={{
                            borderRadius: 1,
                            backgroundColor: "#ECE9F5",
                            color: "#5761FE",
                            p: 0.5,
                            "&:hover": { backgroundColor: "#ECE9F5" },
                          }}
                        >
                          {expandedSpf ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {expandedSpf && (<pre style={{ whiteSpace: 'pre-wrap', padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px', }}>
                          <p className="black-authencate">{'"' + label + '" :'}</p> <p className="red-authencate">{JSON.stringify(acc?.rapidApiData?.records[label], null, 2)}</p>
                        </pre>)}
                      </div>


                    </div> */}
                  </>
                )}
                How to configure domain attributes?{" "}
                <span
                  style={{
                    display: isSmDown ? "inline" : "none",
                    borderRadius: "8px",
                    padding: "4px",
                    border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "8px",
                  }}
                >
                  {" "}
                  {label}
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  my: 1,
                }}
              >
                {" "}
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block" },
                    borderRadius: 1,
                    py: 1,
                    px: 2,
                    border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {label}
                </Typography>
                <Select
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  size="small"
                >
                  <MenuItem value="godaddy">GoDaddy</MenuItem>
                  <MenuItem value="namecheap">NameCheap</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                <Select
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  size="small"
                >
                  <MenuItem value="google">Google WorkSpace</MenuItem>
                  <MenuItem value="microsoft">Microsoft 365</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </Box>
              <Instructions
                domain={domain}
                service={service}
                type={label}
                rapiddata={acc.rapidApiData}
              />
            </>
          )}
        </DialogContent>
        {label === "Custom Tracking" && (
          <DialogActions>
            <Button
              fullWidth={isSmDown}
              variant="contained"
              sx={{ alignSelft: "end", borderRadius: 1 }}
              onClick={checkStatus}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const GooglePostMaster = () => {
  return (
    <Stack
      rowGap={2}
      sx={{
        border: "1px solid #ECE9F5",
        backgroundColor: "#ffffff",
        p: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{ color: "#111927", fontWeight: "700", fontSize: "12px" }}
      >
        Google Postmaster:{" "}
        <span
          style={{
            color: "#55597A",
            fontWeight: "700",
            fontSize: "12px",
          }}
        >
          Domain Health
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexWrap: { xs: "wrap", sm: "nowrap" },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Spam Rate :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                1.2%
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                0.98%
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Domain Reputation :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            IP Reputation :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
const MailItem = ({
  acc,
  index,
  selected,
  handleChange,
  expandAll,
  userType,
  setOpenSnackbar,
  fetchAllMailBoxes,
  userEmail,
}) => {
  // console.log("acc=====>", acc);
  const defaultHourlyLimit = acc?.hourlyLimit || 0;
  const defaultDailyLimit = acc?.maxAllowed || 0;
  const defaultDelay = acc?.delayEmails
    ? Math.floor(acc?.delayEmails / (1000 * 60))
    : 0 || 0;
  const [expanded, setExpanded] = useState(false);
  const [hourlyLimit, setHourlyLimit] = useState(defaultHourlyLimit);
  const [dailyLimit, setDailyLimit] = useState(defaultDailyLimit);
  const [delay, setDelay] = useState(defaultDelay);
  const [openTracking, setOpenTracking] = useState(acc?.openTracking);
  const [clickTracking, setClickTracking] = useState(acc?.clickTracking);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [forwardDomain, setForwardDomain] = useState('');
  const navigate = useNavigate();
  
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  const handleOpenTracking = async (e) => {
    setOpenTracking(e.target.checked);
    try {
      let payload = {
        openTracking: e.target.checked,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );

      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const handleClickTracking = async (e) => {
    setClickTracking(e.target.checked);
    try {
      let payload = {
        clickTracking: e.target.checked,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );

      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const isValidInput = (input) => {
    return /^[0-9]*$/.test(input);
  };
  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);
  const isSaveVisible =
    hourlyLimit !== defaultHourlyLimit ||
    dailyLimit !== defaultDailyLimit ||
    delay !== defaultDelay;
  const [loading, setLoading] = useState(false);
  const mailBoxUpdate = async () => {
    try {
      let payload = {
        hourlyLimit: hourlyLimit || 0,
        delayEmails: delay ? Math.floor(delay * 1000 * 60) : 0,
        maxAllowed: dailyLimit || 0,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );
      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
   const theme=useTheme();
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const mailBoxStatus = mailBoxStatusList.find((item) => item.value === acc.connectionStatus);
  const handleDelete = async (mailboxId) => {
    const id = mailboxId;
    try {
      setIsDeleting(true);
      const res = await axios.delete(`${API_SERVICE_BACKEND}/mailbox/${id}`);
      if (res.status === 200) {
        setIsDeleting(false);
        toast.success("Account deleted successfully!");
        setIsDeleteOpen(false);
        await fetchAllMailBoxes(userEmail);
      } else {
        setIsDeleting(false);
        console.error("Failed to unassign the mailbox:", res);
        toast.error("Failed to unassign the mailbox!");
        //alert('Failed to unassign the mailbox');
      }
    } catch (err) {
      setIsDeleting(false);
      console.error("Error when deleting the mailbox:", err);
      toast.error("An error occurred while deleting the mailbox!");
      //alert('An error occurred while deleting the mailbox');
    } finally {
      setLoading(false);
      setIsDeleting(false);
    }
  };
  const fetchMailboxStats = async (signal) => {
    try {
      const res = await fetch(
        `${API_SERVICE_BACKEND}/getmailboxLogs/${acc?._id}`
      );
      const userdata = await res.json();
      const sortedData = userdata?.data?.logs?.sentHistory || [];
      return sortedData
    } catch (error) {

    }

  };

  const {
    data: chartData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["mailboxStats", acc?._id],
    queryFn: ({ signal }) => fetchMailboxStats(signal),
    enabled: isDrawerOpen,
    staleTime: Infinity,
    keepPreviousData: true,
  });
  const queryClient = useQueryClient();

  const checkSetupAndFixErrorStatus = async (type) => {
    switch (type) {
      case "SPF":
        return acc.rapidApiData?.records?.SPF?.includes("v=spf1");
      case "DKIM":
        return true;
      case "DMARC":
        return acc.rapidApiData.records.DMARC.includes("v=DMARC1");
      case "Custom Tracking":
        return true;
      case "MX":
        return acc.rapidApiData.records.MX?.length > 0;
      default:
        return false;

    }
  };

  // get forward domain 
  const getForwardDomain = async () => {
    try{
      const domain = acc.senderEmail?.split("@")?.[1]
      const response = await axios.get(`${API_SERVICE_BACKEND}/DFYInfraSetup/getForwardDomain?domain=${domain}`)
      setForwardDomain(response.data.data ?? '')
    }catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    getForwardDomain()
    return () => {
      queryClient.invalidateQueries(["mailboxStats"])
    }
  }, [])
  

  return (
    <>
      <Card
        key={index}
        sx={{
          border: "1px solid #EAECF0",
          borderRadius: "12px",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 }, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              mb: 2,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {acc?.provider === "google" ? (
                <GmailIcon size={"20px"} />
              ) : acc?.provider === "microsoft" ? (
                <MicrosoftIcon size={"20px"} />
              ) : <DraftsIcon size={"20px"} color="primary" />}
              <Typography
                sx={{ fontWeight: "700", color: theme.palette.text.primary, fontSize: "14px" }}
              >
                {acc.senderEmail}
              </Typography>
              <Chip
                variant="outlined"
                size="small"
                label={mailBoxStatus?.label}
                sx={{
                  color: mailBoxStatus?.color,
                  fontSize: "12px",
                  fontWeight: "700",
                  borderRadius: 1,
                  borderColor: mailBoxStatus?.color,
                  p: 0.5,
                }}
              />
              {acc?.healthStatus ? (
                <Chip
                  label={`${acc?.healthStatus}%`}
                  variant="outlined"
                  sx={{ marginLeft: 2 }}
                />
              ) : <Tooltip title={"Health Status Not Available"} placement="top" arrow>
                <InfoOutlined />
              </Tooltip>}

            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", sm: "flex-end" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              {forwardDomain && <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#5761FE",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <LanguageOutlined />
                Domain:{" "}
                <span style={{ color: "#000000" }}>
                  {forwardDomain}
                </span>
              </Typography>}
              {" "}
              {loading ? (
                <CircularProgress size={25} />
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    display: {
                      xs: "none",
                      sm: isSaveVisible ? "block" : "none",
                    },
                    borderRadius: 1,
                    py: "4.6px",
                  }}
                  onClick={mailBoxUpdate}
                >
                  Save
                </Button>
              )}
              <IconButton
                onClick={() => setIsDrawerOpen(true)}
                sx={{
                  borderRadius: 1,
                  backgroundColor: "#ECE9F5",
                  color: theme.palette.primary.main,
                  p: 0.5,
                  "&:hover": { backgroundColor: "#ECE9F5" },
                }}
              >
                <BarChartOutlined />
              </IconButton>
              <Button
                variant="outlined"
                color="error"
                disabled={acc.connectionStatus === "WARMUP"}
                onClick={() => setIsDeleteOpen(true)}
                sx={{ borderRadius: 1, py: "4.6px" }}
                size="small"
              >
                Delete
              </Button>
              {/* {acc?.healthStatus && (
                <Box
                  sx={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    backgroundColor: "#F8F7FC",
                    border: "1px solid #5761FE",
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={acc?.healthStatus ? acc?.healthStatus : 0}
                    sx={{ width: "100%" }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#111927",
                      fontSize: "14px",
                    }}
                  >
                    {acc?.healthStatus ? acc?.healthStatus : 0}%
                  </Typography>
                </Box>
              )} */}
              {/* <IconButton
                onClick={handleExpand}
                sx={{
                  borderRadius: 1,
                  backgroundColor: "#ECE9F5",
                  color: "#5761FE",
                  p: 0.5,
                  "&:hover": { backgroundColor: "#ECE9F5" },
                }}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton> */}
            </Box>
          </Box>
          <Divider sx={{ borderColor: alpha("#111927", 0.1) }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              p: 2,
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.text.secondary,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Daily Sending Limits
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {/* <span
                style={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                50
              </span> */}
                <TextField
                  sx={{
                    width: "40px",
                    border: "none",

                    "& .MuiOutlinedInput-input": {
                      p: 0,
                      px: 1,
                      border: "none",
                    },
                    "& input": {
                      fontWeight: "700",
                      color: theme.palette.text.primary,
                      fontSize: "14px",
                    },
                  }}
                  disabled={acc.connectionStatus === "WARMUP"}
                  InputProps={{
                    // readOnly: userType === "enterprise" ? false : true,
                    readOnly: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="This is a tooltip!" arrow>
                          <InfoOutlined
                            sx={{
                              color: alpha("#55597A", 0.5),
                              fontSize: "16px",
                            }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={dailyLimit}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      const value =
                        e.target.value === ""
                          ? e.target.value
                          : parseInt(e.target.value);
                      setDailyLimit(value);
                    }
                  }}
                />
                <Tooltip title="Number of emails you can send per DAY" arrow>
                  <InfoOutlined
                    sx={{ color: theme.palette.text.secondary, fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color:theme.palette.text.secondary,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Hourly Sending Limits
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {/* <span
                style={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                10
              </span> */}
                <TextField
                  sx={{
                    width: "40px",
                    border: "none",
                    "& .MuiOutlinedInput-input": {
                      p: 0,
                      px: 1,
                      border: "none",
                    },
                    "& input": {
                      fontWeight: "700",
                      color: theme.palette.text.primary,
                      fontSize: "14px",
                    },
                  }}
                  disabled={acc.connectionStatus === "WARMUP"}
                  InputProps={{
                    // readOnly: userType === "enterprise" ? false : true,
                    readOnly: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="This is a tooltip!" arrow>
                          <InfoOutlined
                            sx={{
                              color: alpha("#55597A", 0.5),
                              fontSize: "16px",
                            }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={hourlyLimit}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      const value =
                        e.target.value === ""
                          ? e.target.value
                          : parseInt(e.target.value);
                      setHourlyLimit(value);
                    }
                  }}
                />
                <Tooltip title="Number of emails you can send per HOUR" arrow>
                  <InfoOutlined
                    sx={{ color: theme.palette.text.secondary, fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.text.secondary,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Delay Between Emails
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    color: theme.palette.text.primary,
                    fontSize: "14px",
                  }}
                >
                  <TextField
                    sx={{
                      width: "40px",
                      border: "none",
                      "& .MuiOutlinedInput-input": {
                        p: 0,
                        px: 1,
                        border: "none",
                      },
                      "& input": {
                        fontWeight: "700",
                        color: theme.palette.text.primary,
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{
                      // readOnly: userType === "enterprise" ? false : true,
                      readOnly: true,
                    }}
                    disabled={acc.connectionStatus === "WARMUP"}
                    variant="outlined"
                    value={delay}
                    onChange={(e) => {
                      if (isValidInput(e.target.value)) {
                        const value =
                          e.target.value === ""
                            ? e.target.value
                            : parseInt(e.target.value);
                        setDelay(value);
                      }
                    }}
                  />{" "}
                  min
                </span>

                <Tooltip title="Random delay between the emails you send" arrow>
                  <InfoOutlined
                    sx={{ color: theme.palette.text.secondary, fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.text.secondary,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Click to Setup or Fix Errors
              </Typography>
              {/* <Stack
                rowGap={2}
                sx={{
                  border: "1px solid #ECE9F5",
                  display: "flex",
                  backgroundColor: "#ffffff",
                  p: 2,
                  borderRadius: 1,
                }}
              > */}

              <Stack
                rowGap={2}
                sx={{
                  
                  border: "1px solid #ECE9F5",
                  backgroundColor: "#ffffff",
                  p: 2,
                  borderRadius: 1,
                }}
              >
                {/* <Typography
                  sx={{ fontWeight: "700", color: "#111927", fontSize: "12px" }}
                >
                  Click to Setup or Fix Errors:
                </Typography> */}
                <Box
                  sx={{
                   
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomButton
                    label={"SPF"}
                    error={checkSetupAndFixErrorStatus("SPF") ? false : true}

                    disabled={acc.connectionStatus === "WARMUP"}
                    handleClick={() => { }}
                    acc={acc}
                  />
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderColor: alpha("#111927", 0.1),
                      display: { xs: "none", sm: "block" },
                    }}
                  />
                  {/* <CustomButton
                  label={"DKIM"}
                  error={
                    acc?.rapidApiData?.records?.DKIM?.length > 0 ? false : true
                  }
                  // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DKIM === "Found") ? false : true}
                  handleClick={() => { }}
                  acc={acc}
                /> */}
                  <CustomButton
                    label={"DMARC"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={checkSetupAndFixErrorStatus("DMARC") ? false : true}
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DMARC === "Found") ? false : true}
                    handleClick={() => { }}
                    acc={acc}
                  />
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderColor: alpha("#111927", 0.1),
                      display: { xs: "none", sm: "block" },
                    }}
                  />
                  <CustomButton
                    label={"MX"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={checkSetupAndFixErrorStatus("MX") ? false : true}
                    handleClick={() => { }}
                    acc={acc}
                  />
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderColor: alpha("#111927", 0.1),
                      display: { xs: "none", sm: "block" },
                    }}
                  />
                  {/* <CustomButton
                  label={"Redirection"}
                  error={true}
                  handleClick={() => {}}
                /> */}
                  <CustomButton
                    label={"Custom Tracking"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={checkSetupAndFixErrorStatus("Custom Tracking") ? false : true}
                    handleClick={() => { }}
                    acc={acc}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </Box>
              </Stack>
              {/* </Stack> */}
            </Box>



            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Delivery Score
              </Typography>
              <Box
                sx={{
                  borderRadius: 4,
                  px: 1,
                  backgroundColor: "#00AA38",
                  display: "inline",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "12px",
                    color: "#ffffff",
                  }}
                >
                  {acc?.performance?.sending?.value
                    ? acc?.performance?.sending?.value +
                      acc?.performance?.sending?.unit
                    : 0 + "%"}
                  {/* 99.5% */}
            {/* </Typography>
              </Box>
            </Box> */}
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            /> */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Open Tracking
              </Typography>
              <IOSSwitch onChange={handleOpenTracking} checked={openTracking} disabled={acc.connectionStatus === "WARMUP"} />
            </Box> */}
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            /> */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Click Tracking
              </Typography>
              <IOSSwitch
                onChange={handleClickTracking}
                checked={clickTracking}
                disabled={acc.connectionStatus === "WARMUP"}
              />
            </Box> */}
          </Box>
          {/* {expanded && (
            <Stack
              sx={{
                borderRadius: 2,
                p: 2,
                backgroundColor: "#F8F7FC",
                border: "1px solid #ECE9F5",
              }}
              rowGap={2}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#5761FE",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <LanguageOutlined />
                Domain:{" "}
                <span style={{ color: "#000000" }}>
                  {acc.senderEmail.split("@")?.[1]}
                </span>
              </Typography>
              <Stack
                rowGap={2}
                sx={{
                  border: "1px solid #ECE9F5",
                  backgroundColor: "#ffffff",
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Typography
                  sx={{ fontWeight: "700", color: "#111927", fontSize: "12px" }}
                >
                  Click to Setup or Fix Errors:
                </Typography>
                <Divider sx={{ borderColor: alpha("#111927", 0.1) }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomButton
                    label={"SPF"}
                    error={
                      acc?.rapidApiData?.records?.SPF?.length > 0 ? false : true
                    }
                    
                    disabled={acc.connectionStatus === "WARMUP"}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  <CustomButton
                  label={"DKIM"}
                  error={
                    acc?.rapidApiData?.records?.DKIM?.length > 0 ? false : true
                  }
                  // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DKIM === "Found") ? false : true}
                  handleClick={() => { }}
                  acc={acc}
                />
                  <CustomButton
                    label={"DMARC"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={
                      acc?.rapidApiData?.records?.DMARC?.length > 0
                        ? false
                        : true
                    }
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DMARC === "Found") ? false : true}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  <CustomButton
                    label={"MX"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={
                      acc?.rapidApiData?.records?.MX?.length > 0 ? false : true
                    }
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.MX === "Found") ? false : true}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  <CustomButton
                  label={"Redirection"}
                  error={true}
                  handleClick={() => {}}
                />
                  <CustomButton
                    label={"Custom Tracking"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={false}
                    handleClick={() => {}}
                    acc={acc}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </Box>
              </Stack>
              <GooglePostMaster />
            </Stack>
          )} */}
        </CardContent>
      </Card>
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "red",
            }}
          >
            <ReportProblemOutlined sx={{ mr: 1 }} />
            Delete Sending Account
          </Typography>
          <IconButton onClick={handleClose} sx={{ pr: 0, color: "#111927" }}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: "18px", color:theme.palette.text.primary }}
          >
            Are you sure you want to permanently delete the account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 1 }}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "#e30000" },
            }}
            onClick={() => handleDelete(acc._id)}
            autoFocus
            disabled={isDeleting}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          zIndex: 1300,
        }}
        PaperProps={{
          sx: { minWidth: 350 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            px: 2,
            backgroundColor: "white",
            borderRadius: "12px",
          }}
        >
          {isFetching ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                height: "100%",
                width: "100%"
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {chartData?.length > 0 ? (
                <Stack spacing={1} sx={{ p: 2 }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>Mailbox Stats</Typography>
                  <Divider sx={{ borderColor: "rgba(233, 233, 236, 1)" }} />
                  <Box
                    sx={{
                      p: 2,
                      maxWidth: "70vw",
                      maxHeight: "90vh",
                      minWidth: "60vw",
                      minHeight: "60vh"
                    }}
                  >
                    <Bar
                      options={chartOptions}
                      data={{
                        labels: chartData?.map((data) => moment(data?.calculatedAt).format('MMM DD')),
                        datasets: [
                          {
                            label: "Max Allowed",
                            data: chartData?.map((data) => data?.maxAllowed),
                            backgroundColor: "rgba(233, 233, 236, 1)",
                          },
                          {
                            label: "Emails Sent",
                            data: chartData?.map((data) => data?.emailsSent),
                            backgroundColor: "#5761FE",
                          },
                        ]
                      }}
                    />
                  </Box>
                </Stack>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <QueryStatsOutlined sx={{ fontSize: "42px" }} />
                  <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>No stats found</Typography>
                </Box>
              )}
            </>

          )}
        </Box>
      </Drawer>
    </>
  );
};

const EmailAccounts = ({ sectionRef, id, userEmail, userType }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isSMTP, setIsSMTP] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const handleExpandAll = () => {
    setExpandAll(!expandAll);
  };
  const [mailAccounts, setMailAccounts] = useState([]);
  const [totalMailboxes, setTotalMailboxes] = useState(0);
  const connectedAccounts =
    mailAccounts?.filter((acc) => acc?.assigned?.campaignId !== null).length ||
    0;
  const [selected, setSelected] = useState([]);
  const [dialogType, setDialogType] = useState("");
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [mailBoxStatus, setMailBoxStatus] = useState("all");
  const [showInstructions, setShowInstructions] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const location = useLocation();
  const theme=useTheme();

  const getSearchParams = (search) => new URLSearchParams(search);
  const searchParams = useMemo(
    () => getSearchParams(location?.search),
    [location?.search]
  );
  const [code, setCode] = useState(searchParams?.get("code"));
  // console.log("code=======>", code);
  const EmailProviders = Object.freeze({
    GOOGLE: "google",
    MICROSOFT: "microsoft",
  });
  const handleConnectGoogleAccount = async () => {
    setSessionStorage("emailProviderType", EmailProviders.GOOGLE);
    const expirationTimeCamp = 2 * 60 * 1000;
    Cookies.set("campaignId", null, { expires: expirationTimeCamp });
    const expirationTime = 1 * 60 * 1000;
    Cookies.set("emailProviderType", EmailProviders.GOOGLE, {
      expires: expirationTime,
    });
    const params = new URLSearchParams({
      redirect_uri: googleWebAppConfig.redirect_uri,
      client_id: googleWebAppConfig.client_id,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: googleWebAppConfig.scopes,
    });
    const url = `${googleWebAppConfig.oauth_url}?${params.toString()}`;
    window.open(url, "_self");
  };
  const handleConnectMicrosoftAccount = async () => {
    const expirationTimeCamp = 2 * 60 * 1000;
    Cookies.set("campaignId", null, { expires: expirationTimeCamp });
    setSessionStorage("emailProviderType", EmailProviders.MICROSOFT);
    const expirationTime = 5 * 60 * 1000;
    Cookies.set("emailProviderType", EmailProviders.MICROSOFT, {
      expires: expirationTime,
    });
    const params = new URLSearchParams({
      redirect_uri: microsoftWebAppConfig.redirect_uri,
      client_id: microsoftWebAppConfig.client_id_azure,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: microsoftWebAppConfig.scopes,
    });
    const url = `${microsoftWebAppConfig.oauth_url}?${params?.toString()}`;
    window.open(url, "_self");
  };

  const handleClose = () => {
    setOpen(false);
    setDialogType("");
  };

  const handleConnectEmailProvider = async (payload = {}) => {
    try {
      const res = await axios.post(`${API_SERVICE_BACKEND}/mailboxes/connectSMTP`, {
        ...payload
      });
      await fetchAllMailBoxes(userEmail);
      handleClose();
      toast.success(res?.data?.message, {
        autoClose: 3000,
      });
    } catch (error) {
      toast.error(error?.response?.data?.error ?? "Failed To Connect", {
        autoClose: 3000,
      });
    }
  }

  const [tostrDisplayed, setToastrDisplayed] = useState(false);
  const exchangeCodeForAccessToken = async (code) => {
    const accessTokenUrlsMap = {
      [EmailProviders.GOOGLE]: "/getAccessTokenEnterprice",
      [EmailProviders.MICROSOFT]: "/exchangeCodeForAccessTokenMicrosoft2",
    };
    if (!tostrDisplayed) {
      const emailProviderType = getSessionStorage("emailProviderType");
      console.log(
        "emailProviderType====>",
        accessTokenUrlsMap[emailProviderType]
      );
      const res = await axios.post(
        `${API_SERVICE_BACKEND}/mailbox${accessTokenUrlsMap[emailProviderType]}`,
        {
          code,
          createdBy: userEmail,
        }
      );
      // console.log("res======>", res);
      return res.data;
    }
  };

  // mailbox connection status filter handle
  const handleMailBoxFilterChange = (event) => {
    setMailBoxStatus(event.target.value);
  };
  useEffect(() => {
    const codeFromUrl = searchParams.get("code");
    // console.log("codeFromUrl========>", codeFromUrl);
    if (code && !tostrDisplayed) {
      setToastrDisplayed(true);
      setCode(code);
      setLoading(true);
      exchangeCodeForAccessToken(code)
        .then((res) => {
          let toastrCount = false;
          if (!toastrCount) {
            if (typeof res.resource == "number" && res.resource >= 1) {
              toast.error("Mail box already exist");
              toastrCount = true;
            } else {
              toast.success("Mail box added successfully");
              toastrCount = true;
              // console.log(res.resource.error, "=====error");
            }
            // setTimeout(() => {
            navigate("/dashboard/account", { replace: true });
            // }, 1000);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [
    tostrDisplayed,
    /*code*/
  ]);

  const handleClickOpen = (type) => {
    setDialogType(type);
    setOpen(true);
  };

  const handleChange = (e, acc) => {
    if (e.target.checked) {
      setSelected([...selected, acc]);
    } else {
      setSelected(selected.filter((e) => e !== acc));
    }
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const baseUrl = API_SERVICE_BACKEND;
  // const email = localStorage.getItem("userEmail");

  // const getUserInfo = async () => {
  //   try {
  //     const res = await axios.get(
  //       `https://campaign-app-server-azure-pipeline.azurewebsites.net/api/v1/main/getuserdetail/${email}`
  //     );
  //     const userData = res.data.data;
  //     setShowInstructions(userData?.userInstructions);
  //   } catch (error) {
  //     throw error; // You can choose to re-throw the error or handle it here
  //   }
  // };

  const fetchAllMailBoxes = async (account) => {
    try {
      setLoading(true);
      // const res = await fetch(`${API_SERVICE_BACKEND}/getuserAnalytics?page=${page}&limit=${rowsPerPage}&search=${encodeURIComponent(searchText)}`);
      const url=mailBoxStatus !== 'all' ? `?connectionStatus=${mailBoxStatus}` : '';
      // // const res = await fetch(`${baseUrl}/usermailbox/${account}?connectionStatus=${mailBoxStatus}`);
      const res = await fetch(`${baseUrl}/usermailbox/${account}${url}`);
      const userdata = await res.json();
      const payload={accountsEmails:userdata?.data?.map(item=>item?.senderEmail)||[]}
      if(payload.accountsEmails.length>0){
      const UserHealthData = await axios.post(`${API_ADMIN_SERVICE_BACKEND}/allUserForMailboxes`,payload);
      setTotalMailboxes(userdata?.totalCount ?? 0);
      const result = userdata?.data?.map(itemA => {
        const match = UserHealthData?.data?.mailBoxHealthData?.find(itemB => itemB?.email === itemA?.senderEmail);
        return {
          ...itemA,
          health_score: match ? match.health_score : null,
        };
      });
      setMailAccounts(result);
    }else{
      setMailAccounts(userdata.data ?? []);
      setTotalMailboxes(0)
    }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  };
  useEffect(() => {
    // getUserInfo(userEmail)
    fetchAllMailBoxes(userEmail);
  }, [mailBoxStatus]);
  // console.log("mail accounts", mailAccounts);

  const handlePageChange = (e, value) => {
    setPage(value);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const filteredAccounts = mailAccounts.filter((acc) =>
    acc.senderEmail.includes(search)
  );
  const start = (page - 1) * limit;
  const end = limit * page;
  const paginatedAccounts = filteredAccounts.slice(start, end);

  // const handleDelete = async (mailboxId) => {
  //   const id = mailboxId
  //   try {
  //     const res = await axios.delete(
  //       `${API_SERVICE_BACKEND}/mailbox/${id}`,
  //     );
  //     if (res.status === 200) {
  //       await fetchAllMailBoxes(userEmail);

  //     } else {
  //       console.error('Failed to unassign the mailbox:', res);
  //       alert('Failed to unassign the mailbox');
  //     }
  //   } catch (err) {
  //     console.error('Error when deleting the mailbox:', err);
  //     alert('An error occurred while deleting the mailbox');
  //   } finally {
  //     setLoading(false);
  //   }

  // };

  const pageCount = Math.ceil(filteredAccounts.length / limit);
  useEffect(() => {
    const saveBtn = document.getElementById("save-settings-btn");
    if (saveBtn) {
      saveBtn.style.display = "none";
    }
    return () => {
      if (saveBtn) {
        saveBtn.style.display = "flex";
      }
    };
  }, []);
  return (
    <>
      <Box sx={{ paddingX: "30px" }}>
        <div
          ref={sectionRef}
          id={id}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "32px",
                  color: theme.palette.text.primary,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <span style={{ fontWeight: "700" }}>Email Accounts</span>
                {/* {!isSmDown && (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ borderColor: alpha("#111927", 0.1) }}
                    />
                    <span style={{ fontWeight: "500", fontSize: "20px" }}>
                      Linked Accounts
                    </span>
                  </>
                )} */}
              </Typography>

              <Chip
                sx={{
                  backgroundColor: "#00AA38",
                }}
                label={
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      // color: "#ffffff",
                      lineHeight: 1,
                      color: "#ffffff",
                    }}
                  >
                    {/* {mailBoxStatus== "connected" ? connectedAccounts : totalMailboxes - connectedAccounts} / */}
                  <span style={{ fontWeight: "500", opacity: 0.6 }}>
                    {totalMailboxes || 0}
                  </span>
                  </Typography>
                }
              />
              {/* <Box
                sx={{
                  borderRadius: 4,
                  px: 1,
                  backgroundColor: "#00AA38",
                  display: { xs: "none", sm: "inline" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#ffffff",
                  }}
                >
                  {mailBoxStatus== "connected" ? connectedAccounts : totalMailboxes - connectedAccounts} /
                  <span style={{ fontWeight: "500", opacity: 0.6 }}>
                    {totalMailboxes || 0}
                  </span>
                </Typography>
              </Box> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#111927",
                  }}
                >
                  Linked Accounts
                </Typography>
                <Box
                  sx={{
                    borderRadius: 4,
                    px: 1,
                    backgroundColor: "#00AA38",
                    display: "inline",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#ffffff",
                    }}
                  >
                    {/* {mailBoxStatus== "connected" ? connectedAccounts : totalMailboxes - connectedAccounts} / */}
                    <span style={{ fontWeight: "500", opacity: 0.6 }}>
                      {totalMailboxes|| 0}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Stack rowGap={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="Search by email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined sx={{ color:theme.palette.text.primary ,ml: "5px" }} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{
                  width: { xs: "80%", md: 300 },
                  height: 40,
                  backgroundColor: "white",
                  "& div": { pl: 0.3 },
                  "& div fieldset": {
                    borderRadius: "8px",
                    border: "1px solid #E4E4E5",
                  },
                  "& div input": {
                    py: 1.3,
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    "&::placeholder": {
                      color:theme.palette.text.primary,
                    },
                  },
                }}
                value={search}
                onChange={handleSearchChange}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* connect disconnect filter dropdown */}
                <FormControl sx={{
                  // remove focus effect
                  width: "150px",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent"

                  },
                }}>
                  <InputLabel id="demo-simple-select-label">Connection Status</InputLabel>
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    label="Connection Status"
                    value={mailBoxStatus}
                    onChange={handleMailBoxFilterChange}
                    size="small"
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"connected"}>Connected</MenuItem>
                    <MenuItem value={"disconnected"}>Disconnected</MenuItem>
                  </Select>
                </FormControl>
                {/* <Button
                  sx={{
                    borderRadius: 1,
                    borderColor: "#ECE9F5",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#111927",
                    px: { xs: 1, sm: 2 },
                    backgroundColor: "#ffffff",
                    "&:hover": {
                      borderColor: "#ECE9F5",
                      backgroundColor: "#ffffff",
                    },
                  }}
                  variant="outlined"
                  disableRipple
                  endIcon={expandAll ? <ExpandLess /> : <ExpandMore />}
                  onClick={handleExpandAll}
                >
                  {expandAll ? "Shrink All" : "Expand All"}
                </Button> */}
                {selected.length > 0 ? (
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: 1,
                      backgroundColor: "#5761FE",
                    }}
                    onClick={() => handleClickOpen("delete")}
                  >
                    <DeleteOutline />
                    {!isSmDown && <Typography>Delete</Typography>}{" "}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      display: userType === "enterprise" ? "flex" : "none",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: 1,
                    }}
                    onClick={() => handleClickOpen("add")}
                  >
                    <AddOutlined />
                    {!isSmDown && (
                      <Typography style={{ fontSize: "14px" }}>
                        Add New
                      </Typography>
                    )}{" "}
                  </Button>
                )}
              </Box>
            </Box>

            {!loading && mailAccounts.length > 0 ? (
              <Stack
                rowGap={2}
                sx={{
                  minHeight: "300px",
                  // maxHeight: { xs: "90vh", md: "70vh" },
                  // overflow: "auto",
                  position: "relative",
                  ...scrollBarStyle,
                }}
              >
                {paginatedAccounts.length > 0 ? (
                  paginatedAccounts.map((acc, index) => (
                    <MailItem
                      index={index}
                      acc={acc}
                      selected={selected}
                      handleChange={handleChange}
                      expandAll={expandAll}
                      userType={userType}
                      setOpenSnackbar={setOpenSnackbar}
                      fetchAllMailBoxes={fetchAllMailBoxes}
                      userEmail={userEmail}
                    />
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      No Results
                    </Typography>
                  </Box>
                )}
              </Stack>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ textAlign: "center" }}>
                    No  {mailBoxStatus?.charAt(0)?.toUpperCase() + mailBoxStatus?.slice(1)} Accounts
                  </Typography>
                )}
              </Box>
            )}
            {!loading && paginatedAccounts.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    Rows per page
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={limit}
                    //label="Rows per page"
                    onChange={handleLimitChange}
                    size="small"
                    sx={{ "& .MuiSelect-select": { width: "30px", py: "4px" } }}
                    variant="outlined"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </Box>
              </Box>
            )}
          </Stack>
        </div>

        <Dialog open={open} maxWidth="md" onClose={handleClose} fullScreen={isSmDown}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isSMTP ? <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px",
                mb: 1.5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Connect a New Email Account
            </Typography>
              : <Typography variant="h6">
                {dialogType === "add"
                  ? "Connect Your Account"
                  : dialogType === "delete"
                    ? "Delete Account"
                    : ""}{" "}
              </Typography>}
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{
                marginLeft: "10px",
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            {dialogType === "add" ? (
              <>
                <ConnectNewAccount
                  handleConnectGoogleAccount={handleConnectGoogleAccount}
                  handleConnectMicrosoftAccount={handleConnectMicrosoftAccount}
                  handleConnectEmailProvider={handleConnectEmailProvider}
                  showInstructions={true}
                  setIsSMTP={setIsSMTP}
                />
                {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  width: "100%",
                }}
              >
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      width: { xs: "100px", sm: "auto" },
                      height: { xs: "100px", sm: "auto" },
                    }}
                    onClick={handleConnectGoogleAccount}
                  >
                    <GmailIcon />
                  </Button>

                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Google Workspace
                  </Typography>
                </Stack>
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      width: { xs: "100px", sm: "auto" },
                      height: { xs: "100px", sm: "auto" },
                      // height: "144px",
                      // width:'144px'
                    }}
                    onClick={handleConnectMicrosoftAccount}
                  >
                    <MicrosoftIcon />
                  </Button>

                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Microsoft
                  </Typography>
                </Stack>
              </Box> */}
              </>
            ) : dialogType === "delete" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Typography>
                  Are you sure you want to delete the
                  {selected.length > 1
                    ? ` ${selected.length} accounts`
                    : " account"}
                  ?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ borderRadius: 1 }}
                    color="error"
                    onClick={handleClose}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: 1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          ContentProps={{
            sx: {
              background: "#5761FE",
            },
          }}
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          message="Copied  Successfully!"
        />
      </Box>
    </>
  );
};

export default EmailAccounts;
