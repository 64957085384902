import React, { useState } from 'react';
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  TextField,
  Divider,
  Paper,
  Stack,
  Button,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { Close, Search, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

const dummyData = [
  {
    date: '2025-01-22',
    logs: [
      {
        senderEmail: 'linda@fastai2bz.com',
        receiverEmail: 'jelly.mendoza@bdo.global',
        status: 'Bounced',
        accountEmail: 'gaminghawkeye14@gmail.com'
      },
      {
        senderEmail: 'michelle@fastb2bgenrocket.com',
        receiverEmail: 'mailer-daemon@googlemail.com',
        status: 'Bounced',
        accountEmail: 'gaminghawkeye14@gmail.com'
      }
    ]
  },
  {
    date: '2025-01-21',
    logs: [
      {
        senderEmail: 'john.doe@example.com',
        receiverEmail: 'jane.smith@company.com',
        status: 'Delivered',
        accountEmail: 'user1@gmail.com'
      }
    ]
  },
  {
    date: '2025-01-20',
    logs: [
      {
        senderEmail: 'emily.ray@autotest.com',
        receiverEmail: 'support@techhub.com',
        status: 'Delivered',
        accountEmail: 'admin@autotest.com'
      },
      {
        senderEmail: 'mark.zed@financer.org',
        receiverEmail: 'hr@recruitme.com',
        status: 'Bounced',
        accountEmail: 'financepro@financer.org'
      }
    ]
  },
  {
    date: '2025-01-19',
    logs: [
      {
        senderEmail: 'team@collab.io',
        receiverEmail: 'client@solutions.net',
        status: 'Bounced',
        accountEmail: 'teamadmin@collab.io'
      },
      {
        senderEmail: 'news@updates.org',
        receiverEmail: 'subscriber1@dailynews.com',
        status: 'Delivered',
        accountEmail: 'newsadmin@updates.org'
      }
    ]
  },
  {
    date: '2025-01-18',
    logs: [
      {
        senderEmail: 'support@webhelpdesk.com',
        receiverEmail: 'user@domain.com',
        status: 'Delivered',
        accountEmail: 'supportlead@webhelpdesk.com'
      }
    ]
  },
  {
    date: '2025-01-17',
    logs: [
      {
        senderEmail: 'feedback@retailchain.com',
        receiverEmail: 'shopper@onlinecart.com',
        status: 'Bounced',
        accountEmail: 'feedbackmanager@retailchain.com'
      },
      {
        senderEmail: 'alerts@cybersecure.com',
        receiverEmail: 'itadmin@companysecure.net',
        status: 'Delivered',
        accountEmail: 'alertadmin@cybersecure.com'
      }
    ]
  }
];

const LogDrawer = ({ open, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Filter logs based on search and date range
  const filteredData = dummyData.filter((data) => {
    const dateInRange =
      (!startDate || new Date(data.date) >= new Date(startDate)) &&
      (!endDate || new Date(data.date) <= new Date(endDate));

    const matchesSearch = data.logs.some((log) =>
      Object.values(log).some((value) =>
        value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    return dateInRange && matchesSearch;
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'fixed',
          zIndex: 1400,
          width: '58%',
          height: '75vh',
          top: '13%',
          left: '0%',
          transform: 'translate(-50%, -50%)',
          margin: 'auto',
          padding: '20px',
          overflow: 'auto',
          borderRadius: '16px',
          boxShadow: '0px 9px 46px 0px rgba(0, 0, 0, 0.12)'
        }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5">Logs</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
          />
        </Box>

        <TextField
          fullWidth
          placeholder="Search logs"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{ startAdornment: <Search color="action" /> }}
        />

        <Divider sx={{ my: 2 }} />

        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {filteredData.length > 0 ? (
            filteredData.map((data) => (
              <Paper key={data.date} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {data.date}
                </Typography>
                <List>
                  {data.logs.map((log, index) => (
                    <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <ListItemText
                        primary={`Sender: ${log.senderEmail}`}
                        secondary={`Receiver: ${log.receiverEmail}`}
                      />
                      <Typography variant="body2" color="text.secondary">
                        Status: {log.status}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Account: {log.accountEmail}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            ))
          ) : (
            <Typography variant="body1" color="text.secondary">
              No logs found for the selected criteria.
            </Typography>
          )}
        </Box>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={() => { setStartDate(''); setEndDate(''); setSearchQuery(''); }}>
            Reset
          </Button>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LogDrawer;
