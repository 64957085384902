import React from "react";
import { Stack, Divider, SvgIcon } from "@mui/material";
import SideNavFooterItem from "./side-nav-footer-item";
import Settings01 from "@untitled-ui/icons-react/build/esm/Settings01";
import LogOut01Icon from "@untitled-ui/icons-react/build/esm/LogOut01";
import Headphones02Icon from "@untitled-ui/icons-react/build/esm/Headphones02";
import { useCallback } from "react";
import toast from "react-hot-toast";

import { useAuth } from "src/hooks/use-auth";
import { usePathname } from "src/hooks/use-pathname";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { getSessionStorage } from "src/utils/storage";
import { AccountButton } from "../account-button";
import { useTranslation } from "react-i18next";
import { SideNavSection } from "../vertical-layout/side-nav-section";

const SideNavFooter = ({open, expand, setExpand}) => {
  const router = useRouter();
  const auth = useAuth();
  const user = {
    name: getSessionStorage("fullName"),
    email: getSessionStorage("userEmail"),
  };
  const pathname = usePathname();

  const handleLogout = useCallback(async () => {
    try {
      switch (auth.issuer) {
        case Issuer.Amplify: {
          await auth.signOut();
          break;
        }

        case Issuer.Auth0: {
          await auth.logout();
          break;
        }

        case Issuer.Firebase: {
          await auth.signOut();
          break;
        }

        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router]);

  function handleAuxClick(event) {
    if (event.button === 1) {
      event.preventDefault(); // Prevent the default behavior of opening links in new tabs
    }
    if (event.metaKey) {
      event.preventDefault(); // Prevent the default behavior if Command key is pressed
    }
}
  const { t } = useTranslation();
  const footerSections =[
    // {
    //   title: t("Settings"),
    //   path: paths.dashboard.account,
    //   icon: (
    //     <SvgIcon fontSize="medium">
    //         <Settings01 />
    //     </SvgIcon>
    //   ),
    // },
    {
      title: t("Support Center"),
      path: paths.dashboard.supportCenter,
      icon: (
        <SvgIcon fontSize="medium">
          <Headphones02Icon />
        </SvgIcon>
      ),
    },
  ]
  return (
    <Stack rowGap={2}>
      {/* <Stack
        component="ul"
        spacing={0.5}
        sx={{
          listStyle: "none",
          m: 0,
          p: 0,
          gap: "10px",
          alignDelf: "stretch",
          //borderTop: "0.2px inset",
          color: "#F2F4F7",
          fontFamily: "DM Sans",
        }}
      >
        <SideNavFooterItem
          active={paths.dashboard.account === pathname}
          depth={0}
          disabled={false}
          icon={<Settings01 />}
          key={"Settings"}
          label={""}
          open={true}
          path={paths.dashboard.account}
          handleClick={(e) => {handleAuxClick(e)}}
          title={"Settings"}
        />
        <SideNavFooterItem
          active={paths.dashboard.supportCenter === pathname}
          depth={0}
          disabled={false}
          icon={<Headphones02Icon />}
          key={"Support Center"}
          label={""}
          open={true}
          path={paths.dashboard.supportCenter}
          handleClick={(e) => {handleAuxClick(e)}}
          title={"Support Center"}
        />
        <SideNavFooterItem
          active={false}
          depth={0}
          disabled={false}
          icon={<LogOut01Icon />}
          key={"Logout"}
          label={""}
          open={true}
          handleClick={handleLogout}
          title={"Log out"}
        /> 
      </Stack> */}
      <Stack
        component="nav"
        sx={{
          flexGrow: 1,
          pl: "8px"
        }}
      >
        {footerSections?.map((section, index) => (
          <SideNavSection
            open={open}
            sx={{
              padding: "0px 4px",
              gap: "10px",
              margin: 0,
              listStyleType : 'none',
              fontFamily: 'DM Sans'
            }}
            key={index}
            pathname={pathname}
            title={section.title}
            path={section.path}
            icon={section.icon}
            subheader={section.subheader}
            items={section.items}
            expand={expand}
            setExpand={setExpand}
            cssVariables={{}}
          />
        ))}
      </Stack>
      <Divider sx={{mx: 2}} />
      <Stack alignItems='center' direction='row' spacing={2} sx={{px: 2}}>
        <AccountButton/>
      </Stack>
    </Stack>
  );
};

export default SideNavFooter;
